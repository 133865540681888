import { HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private spinnerService: SpinnerService) {}

  getHeaders() {
    return {
      headers: new HttpHeaders({
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        // 'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
        // authorization: `bearer 4762f9e8e7237a432ed96d061e048daa60aef3c7feae7aaf4460f4087fcd624756986b79ee4fc9b6364ad1e3549a182ddddc394f729d94718c61f765d93229fac00f8c25af3ffeb727686531552380192a53faa4418e507c4db83d0a7c57a8cff3c321137589a07f1092f9a70b7ecf96b54171099ec7fb432fea3fd556320342`,
      }),
    };
  } 
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone(this.getHeaders());
    this.spinnerService.show();
    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinnerService.hide();
          if (event.body && event.body.status === '403') {
            
          }
        }
      }),
      catchError((error:any) => {
        if (error instanceof HttpErrorResponse) {
          this.spinnerService.hide();
          if (error.status === 403) {
            
            this.router.navigateByUrl('/');
            
          }
          return throwError(error);
        }
        return throwError(error);
      }),
    );
  }
}
