import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComprehensiveEyeExamComponent } from './view/comprehensive-eye-exam/comprehensive-eye-exam.component';
import { InHouseLaboratoryComponent } from './view/in-house-laboratory/in-house-laboratory.component';
import { UnmatchedPortfolioOfSpecializedContactLensesComponent } from './view/unmatched-portfolio-of-specialized-contact-lenses/unmatched-portfolio-of-specialized-contact-lenses.component';
import { EventsComponent } from './view/events/events.component';
import { HomeComponent } from './view/home/home.component';
import { MeetLilianeComponent } from './view/meet-liliane/meet-liliane.component';
import { NewsletterComponent } from './view/newsletter/newsletter.component';
import { OphthalmicLensesComponent } from './view/ophthalmic-lenses/ophthalmic-lenses.component';
import { TestimonialsComponent } from './view/testimonials/testimonials.component';
import { SemiScleralsAndScleralsContactLensManagementComponent } from './view/semi-sclerals-and-sclerals-contact-lens-management/semi-sclerals-and-sclerals-contact-lens-management.component';
import { MyopiaManagementComponent } from './view/myopia-management/myopia-management.component';
import { PresbyopiaManagementComponent } from './view/presbyopia-management/presbyopia-management.component';
import { ServicesComponent } from './view/services/services.component';
import { KidsCornerComponent } from './view/kids-corner/kids-corner.component';
import { SpecialitiesComponent } from './view/specialities/specialities.component';
import { LowVisionAidsComponent } from './view/low-vision-aids/low-vision-aids.component';
import { KidsEyewearComponent } from './view/kids-eyewear/kids-eyewear.component';
import { KidsSunglassesComponent } from './view/kids-sunglasses/kids-sunglasses.component';
import { KidsLensesComponent } from './view/kids-lenses/kids-lenses.component';
import { OurPracticeComponent } from './view/our-practice/our-practice.component';
import { CollectionsComponent } from './view/collections/collections.component';
import { BlogComponent } from './view/blog/blog.component';
import { WhoAreWeComponent } from './view/who-are-we/who-are-we.component';
import { ContactLensesComponent } from './view/contact-lenses/contact-lenses.component';
import { FaqComponent } from './view/faq/faq.component';
import { BookAnAppointmentComponent } from './view/book-an-appointment/book-an-appointment.component';
import { ContactUsComponent } from './view/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './view/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './view/privacy-policy/privacy-policy.component';
import { BookingFormComponent } from './view/booking-form/booking-form.component';
import { AuthGuard } from './_services/auth-guard.service';
import { AccessoriesComponent } from './view/accessories/accessories.component';

import { UnderConstructionComponent } from './view/under-construction/under-construction.component';

export const routes: Routes = [
  // { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: '', redirectTo: '/coming-soon', pathMatch: 'full' },
  { path: 'coming-soon', component: UnderConstructionComponent, pathMatch: 'full' },
  { path: 'staging', component: HomeComponent, pathMatch: 'full' },
  { path: 'newsletter', component: NewsletterComponent},
  { path: 'testimonial', component: TestimonialsComponent},
  { path: 'events', component: EventsComponent },
  { path: 'meet-liliane', component: MeetLilianeComponent },
  { path: 'ophthalmic-lenses', component: OphthalmicLensesComponent },
  { path: 'comprehensive-eye-refraction-exam', component: ComprehensiveEyeExamComponent },
  { path: 'in-house-laboratory', component: InHouseLaboratoryComponent},
  { path: 'unmatched-portfolio-of-specialized-contact-lenses', component: UnmatchedPortfolioOfSpecializedContactLensesComponent},
  { path: 'semi-sclerals-and-sclerals-contact-lens-management', component: SemiScleralsAndScleralsContactLensManagementComponent},
  { path: 'myopia-management', component: MyopiaManagementComponent},
  { path: 'presbyopia-management', component: PresbyopiaManagementComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'kids-corner', component: KidsCornerComponent},
  { path: 'specialities', component: SpecialitiesComponent},
  { path: 'low-vision-aids', component: LowVisionAidsComponent},
  { path: 'kids-eyewear', component: KidsEyewearComponent},
  { path: 'kids-sunglasses', component: KidsSunglassesComponent},
  { path: 'kids-lenses', component: KidsLensesComponent},
  { path: 'our-practice', component: OurPracticeComponent},
  { path: 'collections', component: CollectionsComponent},
  { path: 'blogs', component: BlogComponent},
  { path: 'who-we-are', component: WhoAreWeComponent},
  { path: 'contact-lenses', component: ContactLensesComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'book-an-appointment', component: BookAnAppointmentComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'accessories', component: AccessoriesComponent},
  { path: 'booking-form', component: BookingFormComponent, canActivate:[AuthGuard],}, 
  // { path: '**', component: HomeComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
