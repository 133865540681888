import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ophthalmic-lenses',
  templateUrl: './ophthalmic-lenses.component.html',
  styleUrls: ['./ophthalmic-lenses.component.scss']
})
export class OphthalmicLensesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  isReadMore = true

  showText() {
     this.isReadMore = !this.isReadMore
  }
  

}
