import { Component, OnInit } from '@angular/core';
import { blogCard, brandsCard, customerReviewsCard, shopNowCard } from 'src/static/data';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sheduleForm : FormGroup;
  constructor(private router : Router , private _helper : HelperService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    let emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    this.sheduleForm = this.formBuilder.group({
      email : ['',[Validators.required,Validators.pattern(emailRegex)]]
    })
  }
  shopNowCard=shopNowCard;
  brandsCard=brandsCard;
  customerCard=customerReviewsCard;
  blogCard=blogCard;

  config: any = {
    count: "10" ,
    idUser: '8499402973',
    tokenUser: 'IGQVJWdlZAIOUsyOGR0S2RnbVJnUHZAHc0E5ZAkthRFdkLXh1cFhOREVSLWh4ZAGVINThlX2VsdVZAnd3pZAUWlsNnJnRjRmaHdoZAmZAiQURFSzVXOUxFT3NOLVBvYkQxR01UY1hhSGhIOVhCWFpSY05XbDRocwZDZD',
    grid: false, 
  };


  onSheduleBooking(){
    console.log(this.sheduleForm);
    
    if(this.sheduleForm.valid){
      this.router.navigate(["/book-an-appointment"],{state : {email : this.sheduleForm.value.email}});
    }
    else{
      this.sheduleForm.markAllAsTouched();
    }
  }
}
