<div class="section-wrapper">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Your Trusted Optical Boutique</span>
    </h1>
    <div class="content-wrapper">
      <div class="image-wrapper">
        <img src="../../../assets/images/our practice.jpg" alt="" />
        <!-- <h5 class="image-meta">
          Optometry Practice Marketing That Delivers Patients
        </h5> -->
      </div>
      <div class="card-wrapper">
        <p>
          VISIQUE Optometrists offers a comprehensive range of vision and eye care
          services, including Low Vision Aids, Pediatric Care, and Specialized Contact Lenses
          for Keratoconus and Post-Surgical Corneas. We utilize advanced instrumentation
          to provide care in a professional, warm, and friendly environment.
        </p>
        <p>
          At VISIQUE, we are dedicated to excellence, from employing top licensed opticians and optometrists to acquiring the latest state-of-the-art equipment and laboratory facilities.
          
        </p>
        <p>
          
          We are committed to upholding the highest ethical standards and take pride in fostering long-term relationships with our customers and their families.

        </p>
        <div class="book-wrapper mb-5">
          <button routerLink="/book-an-appointment">Book An Appointment</button>
        </div>
      </div>
    </div>
  </div>
</div>
