<div class="specialities-wrapper">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>YOUR COMFORT IS <strong>OUR SPECIALITY</strong></h1>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Specialities</span>
    </h1> -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 mb-2">
          <div class="specialities-item">
            <img
              src="../../assets/images/contact-lens-management.png"
              alt="contact-lens-mangement"
            />
            <p routerLink="/semi-sclerals-and-sclerals-contact-lens-management">
              Semi-Sclerals and Sclerals contact<br />
              lens management
            </p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="specialities-item">
            <img
              src="../../assets/images/low-vision-aids.png"
              alt="low-vision-aids"
            />
            <p routerLink="/low-vision-aids">Low vision Aids</p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="specialities-item">
            <img
              src="../../assets/images/Presbyopia Management.png"
              alt="Presbyopia Managemen"
            />
            <p routerLink="/presbyopia-management">Presbyopia Management</p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="specialities-item">
            <img
              src="../../assets/images/Myopia management.png"
              alt="Myopia management"
            />
            <p routerLink="/myopia-management">Myopia management</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div> -->
  </div>
</div>
