<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Myopia Management</h1>
      <p>
        Myopia is one of the most common eye conditions affecting children in today’s
        digital world, and prioritizing myopia management is essential for promoting a
        healthier lifestyle.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Myopia Management</span>
    </h1> -->
    <div class="card-container">
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <img
            src="../../assets/images/myopia-management-main.png"
            alt="Myopia management"
          />
          <h5 class="image-meta">Myopia Management</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
        <p>
          The prevalence of myopia has risen unexpectedly worldwide in recent years, with
          the age of onset decreasing rapidly. When myopia begins at a young age, it tends
          to progress quickly, increasing the risk of high myopia, which can lead to serious
          complications such as retinal detachment, macular hemorrhage, scarring, and
          glaucoma.
        </p>
        <h1>Is There Any Way to Attempt the Management of Myopia?</h1>
        <p>
          In today’s digital era, many children are exposed to near-distance tasks from an
          early age for prolonged periods, contributing to a high rate of myopia.
          Additionally, the lack of outdoor activities accelerates myopia progression,
          particularly in children with myopic parents. As a result, early and effective
          myopia control and preventive measures are crucial to help reduce the
          development of this condition.
        </p>
        <p>
          Myopia management is prescribed by an eye doctor based on the child’s age and
          the severity of the case. Some common techniques include:
        </p>
        <h3>Eyeglasses</h3>
        <p>
          Myopia can impair children&#39;s ability to focus, making it harder for their eyes to
          work together, which contributes to its progression. Eyeglasses are the most
          popular solution for children as they can be fitted with corrective lenses, such as
          bifocal or multifocal lenses. Bifocal lenses have two optical powers—one for
          distance in the top part of the lens and one for near vision in the bottom.
          Multifocal lenses, on the other hand, provide correction for distance,
          intermediate, and near vision, offering a more comprehensive solution.
        </p>
        <h3>Contact Lenses</h3>
        <p>
          Contact lenses are typically prescribed for older children and adults with myopia.
          They are especially useful for sports and can improve self-confidence. Contact
          lenses also offer a wider field of vision compared to eyeglasses. Multifocal contact
          lenses, like multifocal glasses, correct distance vision while reducing eye strain
          from near tasks.
        </p>
        <h3>Atropine Eye Drops</h3>
        <p>
          Atropine eye drops, given at low doses daily, are used to slow the progression of
          myopia, particularly in children aged 8 to 15. However, since they only help in
          reducing myopia progression, corrective eyewear such as eyeglasses or contact
          lenses is still necessary.
        </p>
        <h3>Orthokeratology (Ortho-K)</h3>
        <p>
          Studies show that Ortho-K can reduce myopia progression in children by up to
          50%. This method involves wearing rigid contact lenses at night to temporarily
          reshape the cornea, allowing light to focus better on the retina. It provides clear
          vision during the day without the need for eyeglasses or contact lenses.
        </p>
        <h3>VISIQUE Myopia Treatments</h3>
        <p>
          At VISIQUE, we specialize in advanced myopia treatments, working closely with
          ophthalmologists to provide a comprehensive management and follow-up
          program. We understand that basic treatments are no longer sufficient for
          addressing myopia. With various effective methods available to slow its
          progression, we are dedicated to finding the best treatment plan for your child.
          Schedule an appointment with our specialized team today to explore the most
          suitable options for managing myopia.
        </p>
      </div>
      <div class="card-image col-md-5">
        <img
        
        src="../../assets/images/New-Myopia-Main.jpg"
        alt="Myopia management"
      />
      </div>
      <!-- <img
        class="card-image col-md-5"
        src="../../assets/images/New-Myopia-Main.jpg"
        alt="Myopia management" -->
      <!-- /> -->
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Can myopia be inherited?</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It has been shown that the risk of developing myopia increases if one parent has
                been diagnosed with the condition, although there is no scientific consensus on
                whether it is hereditary.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are the symptoms of myopia?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Symptoms of myopia often include difficulty reading or seeing distant objects or
                words, while nearby items remain clear. In addition, frequent eye strain,
                squinting, and headaches can also indicate the presence of myopia.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How can I know if I have myopia?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                During a visit to your eye care professional or clinic, a comprehensive eye exam
                can determine whether you have myopia. The doctor will assess your vision using
                a visual acuity test, and if nearsightedness is detected, a retinoscope is used to
                accurately measure the degree of the refractive error.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are the methods of management for myopia considered effective?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  There are various effective ways to control and slow the progression of myopia.
                  Wearing prescription eyeglasses is one option. Another approach involves myopia
                  management contact lenses, which not only improve vision in children but also
                  help boost their confidence and self-esteem.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is there a myopia management specialist I can refer to?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  You can schedule an appointment and visit any of our VISIQUE Optometrists
                  stores, where a specialist will recommend the most suitable product for your
                  condition.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Can wearing traditional eyeglasses make myopia worse over time?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                While traditional glasses don&#39;t correct the underlying vision issue of myopia, they
                do not weaken the eyes. Consulting an eye care professional for myopia
                management glasses, however, can provide a more specialized approach to
                managing the condition.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Can myopia progression stop at a certain age?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Research indicates that myopia progression ceases in about half of children by
                age 16, three-quarters by age 18, and 90% by age 21. To manage myopia
                effectively until it stabilizes, it’s important to employ appropriate strategies. As
                your child grows, the methods for managing myopia will adapt to their evolving
                needs, with specialists offering tailored recommendations.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Do I still have to go for eye exams and checkups after my myopia became
                  stable?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Consistent eye monitoring and regular exams are essential, as elevated myopia
                levels can lead to a higher risk of developing eye diseases and vision problems
                later on.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  When should I start with myopia management for my child?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It’s crucial to start managing myopia as soon as it&#39;s diagnosed. As a child’s myopia
                develops, their distance vision may deteriorate rapidly. To help mitigate the
                progression and reduce the overall impact, consult with your eye doctor about
                management strategies as soon as the condition is identified.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
