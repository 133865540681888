import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { HomeComponent } from './home/home.component';
import { ShopNowCardComponent } from './shop-now-card/shop-now-card.component';
import { OurBrandsComponent } from './our-brands/our-brands.component';
import { CustomerCardComponent } from './customer-card/customer-card.component';
import { BlogComponent } from './blog/blog.component';
import { InstgarmCardComponent } from './instgarm-card/instgarm-card.component';
import { NgxInstaModule } from 'ngx-insta';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { EventsComponent } from './events/events.component';
import { MeetLilianeComponent } from './meet-liliane/meet-liliane.component';
import { OphthalmicLensesComponent } from './ophthalmic-lenses/ophthalmic-lenses.component';
import { ComprehensiveEyeExamComponent } from './comprehensive-eye-exam/comprehensive-eye-exam.component';
import { InHouseLaboratoryComponent } from './in-house-laboratory/in-house-laboratory.component';
import { UnmatchedPortfolioOfSpecializedContactLensesComponent } from './unmatched-portfolio-of-specialized-contact-lenses/unmatched-portfolio-of-specialized-contact-lenses.component';
import { SemiScleralsAndScleralsContactLensManagementComponent } from './semi-sclerals-and-sclerals-contact-lens-management/semi-sclerals-and-sclerals-contact-lens-management.component';
import { MyopiaManagementComponent } from './myopia-management/myopia-management.component';
import { PresbyopiaManagementComponent } from './presbyopia-management/presbyopia-management.component';
import { ServicesComponent } from './services/services.component';
import { KidsCornerComponent } from './kids-corner/kids-corner.component';
import { SpecialitiesComponent } from './specialities/specialities.component';
import { LowVisionAidsComponent } from './low-vision-aids/low-vision-aids.component';
import { AppRoutingModule } from '../app-routing.module';
import { KidsEyewearComponent } from './kids-eyewear/kids-eyewear.component';
import { KidsSunglassesComponent } from './kids-sunglasses/kids-sunglasses.component';
import { KidsLensesComponent } from './kids-lenses/kids-lenses.component';
import { InstagramFeedComponent } from './instagram-feed/instagram-feed.component';
import { OurPracticeComponent } from './our-practice/our-practice.component';
import { CollectionsComponent } from './collections/collections.component';
import { WhoAreWeComponent } from './who-are-we/who-are-we.component';
import { ContactLensesComponent } from './contact-lenses/contact-lenses.component';
import { FaqComponent } from './faq/faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatIconModule } from '@angular/material/icon';
import { BookAnAppointmentComponent } from './book-an-appointment/book-an-appointment.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { ContactUsComponent } from './contact-us/contact-us.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BookingFailurePopupComponent, BookingFormComponent, BookingSuccessPopupComponent } from './booking-form/booking-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxWhastappButtonModule } from 'ngx-whatsapp-button';
import { AccessoriesComponent } from './accessories/accessories.component';
import { SeoPageComponent } from './seo-page/seo-page.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';



@NgModule({
  declarations: [
    HomeSliderComponent,
    HomeComponent,
    ShopNowCardComponent,
    OurBrandsComponent,
    CustomerCardComponent,
    BlogComponent,
    InstgarmCardComponent,
    NewsletterComponent,
    TestimonialsComponent,
    EventsComponent,
    MeetLilianeComponent,
    OphthalmicLensesComponent,
    ComprehensiveEyeExamComponent,
    InHouseLaboratoryComponent,
    UnmatchedPortfolioOfSpecializedContactLensesComponent,
    SemiScleralsAndScleralsContactLensManagementComponent,
    MyopiaManagementComponent,
    PresbyopiaManagementComponent,
    ServicesComponent,
    KidsCornerComponent,
    SpecialitiesComponent,
    LowVisionAidsComponent,
    KidsEyewearComponent,
    KidsSunglassesComponent,
    KidsLensesComponent,
    InstagramFeedComponent,
    OurPracticeComponent,
    CollectionsComponent,
    WhoAreWeComponent,
    ContactLensesComponent,
    FaqComponent,
    BookAnAppointmentComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    BookingFormComponent,
    BookingSuccessPopupComponent,
    BookingFailurePopupComponent,
    AccessoriesComponent,
    SeoPageComponent,
    UnderConstructionComponent,
  ],
  imports: [
    CommonModule,
    NgxInstaModule,
    AppRoutingModule,
    AccordionModule.forRoot(),
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatDialogModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    CarouselModule,
    NgxWhastappButtonModule  
  ]
})
export class ViewModule { }
