<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Presbyopia Management</h1>
      <p>
        Presbyopia is a vision condition characterized by the reduced ability to focus on
        nearby objects. This occurs due to the natural aging process, which causes the
        crystalline lens to lose flexibility and the ciliary muscles, responsible for
        controlling lens focus, to weaken. Addressing presbyopia through proper
        management is essential for maintaining a healthier lifestyle.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Presbyopia Management</span>
    </h1> -->
    <div class="card-container">
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <img
            src="../../assets/images/presbyopia-management.png"
            alt="presbyopia management"
          />
          <h5 class="image-meta mt-2">Presbyopia Management</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
        <h1>
          Looking for the best Eye Care Center for Presbyopia Management?
        </h1>
        <p>
          Progressive lenses are multifocal lenses featuring a seamless transition of
          increasing lens power from top to bottom, added to the wearer&#39;s prescription.
          The gradient starts with minimal or no additional power at the top and gradually
          reaches maximum magnification at the bottom.
        </p>
        <p>
          The length of the progressive power gradient on the lens surface typically ranges
          between 12 and 20 mm, with the final addition power between 1.00 and 2.50
          diopters for most wearers. The prescribed addition depends on the patient&#39;s level
          of presbyopia, which is closely related to age. By tilting their head or moving their
          eyes through the appropriate part of the vertical progression, wearers can adjust
          the lens power needed for clear vision at different distances.
        </p>
        <h3>
          The advancement of progressive lenses offers both eye care professionals and
          patients a versatile option for optical correction.
        </h3>
        <ul>
          <li>
            The brain needs time to learn and adapt to progressive lenses, so it&#39;s
            important to understand that wearers will go through a short adjustment
            period.
          </li>
          <li>
            This adaptation phase typically lasts a few days, but for some, it can extend
            to a couple of weeks. During this time, mild side effects like light dizziness
            or headaches may occur.
          </li>
          <li>
            Progressive lenses have evolved significantly, with newer designs offering
            wider intermediate zones and minimal peripheral distortion, allowing for a
            smoother transition between distances and quicker adaptation.
          </li>
          <li>
            These lenses come in a variety of materials, including plastic,
            polycarbonate, high-index, and photochromic options.
          </li>
        </ul>
        <p class="card-bottom-quote">
          "At VISIQUE Optometrists, we highly recommend transition lenses, also known as
          tinted polymers, for optimal presbyopic correction."
        </p>
        <p>
          Successfully fitting progressive lenses is key to optimizing visual performance. In
          addition to selecting the right product, it’s essential to take precise
          measurements and consider the wearer’s lifestyle needs, such as work-related
          vision tasks or personal daily routines. This ensures proper lens fitting, leading to
          comfortable vision and a shorter adaptation period.
        </p>
      </div>
      <img class="card-image"
      src="../../assets/images/presbyopia-management.png"
      alt="presbyopia management"
    />
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is it possible to have presbyopia and another eye condition at the same time?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It is very common to have presbyopia, as well as another eye condition at the
                same time. You can check with your eye care professionals to see what refractive
                errors you may have, such as myopia and astigmatism.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> How does presbyopia occur? </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Presbyopia typically occurs around age 45, when the eye&#39;s natural lens hardens,
                making it difficult to focus on nearby objects.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Which age group is more likely to develop presbyopia?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Presbyopia often starts to develop around the age of 40, when people begin to
                notice a reduced ability to focus on nearby objects.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are some signs and symptoms of presbyopia?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Symptoms of presbyopia include needing to hold reading materials at a distance
                to see clearly, frequent headaches, eye fatigue, difficulty reading smaller text, and
                trouble shifting focus between distant and close objects.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I know if I have presbyopia?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                You can visit an eye care clinic for a dilated eye examination by a specialist, who
                will diagnose presbyopia based on the results of the exam.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
