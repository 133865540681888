import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-lenses',
  templateUrl: './contact-lenses.component.html',
  styleUrls: ['./contact-lenses.component.scss']
})
export class ContactLensesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
