import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor( private http : HttpClient) { }
  baseUrl : string = environment.apiUrl;
  webUrl : string = environment.webUrl;

  POST(endpoint:string,data:any){
    return this.http.post(`${this.baseUrl}/${endpoint}`, data).pipe(map((response:any) => response));
  }
  GET(endpoint:string){
    return this.http.get(`${this.baseUrl}/${endpoint}`).pipe(map((response:any) => response))
  }
  WEBPOST(endpoint:string,data:any){
    return this.http.post(`${this.webUrl}/${endpoint}`,data).pipe(map((response:any) => response))
  }
}
