<div class="terms-and-conditions-wrapper ml-5 mr-5">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Terms & Conditions</span>
    </h1>
    <div class="card-wrapper">
      <p>
        Welcome to the VISIQUE website. The following Terms of Service govern
        your use of this website.<br> PLEASE READ THESE TERMS CAREFULLY AS THEY
        CONTAIN IMPORTANT INFORMATION THAT MAY AFFECT YOUR LEGAL RIGHTS,
        INCLUDING REQUIRING INDIVIDUAL ARBITRATION OF ANY POTENTIAL LEGAL
        DISPUTES BETWEEN YOU AND LENSBOOKING.COM AND WAIVING ANY RIGHT TO
        PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.<br> By
        using, registering with, or shopping at the Lensbooking.com website, you
        accept these Terms, including this arbitration provision so please read
        them carefully
      </p>
      <p>
        By using this Website, you affirm that you are able and legally
        competent to agree to and comply with these Terms. If you do not meet
        these requirements, you must not register for or purchase products from
        the Website.
      </p>
      <p>
        If you do not agree to these Terms, then you may not use, register for
        or shop at this Website. If at any time you do not accept all of these
        Terms, you must immediately stop using the Website.
      </p>
      <h5>Privacy</h5>
      <p>
        Please review our Privacy Policy, which also governs your visit to
        Lensbooking.com, to understand our privacy-related practices.
      </p>
      <h5>Not professional advice</h5>
      <p>
        Any information supplied through this Website or by any of our employees
        or agents, whether by telephone, e-mail, letter, facsimile, or another
        form of communication, is for informational purposes or general guidance
        and does not constitute medical or other professional advice.
        Health-related information provided through this Website is not a
        substitute for medical advice and it is important that you not make
        medical decisions without first consulting your physician or other
        healthcare professional. The receipt of any questions or feedback you
        submit to us does not create a professional relationship and does not
        create any privacy interests other than those described in our Privacy
        Policy.
      </p>
      <p>
        The information presented on or through the Website is available solely
        for general information purposes. We do not warrant the accuracy,
        completeness, or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Website, or by anyone who
        may be informed of any of its contents.
      </p>
      <h5>Electronic communications</h5>
      <p>
        When you visit Lensbooking.com or send e-mails to us, you are
        communicating with us electronically and are consenting to receive
        communications from us electronically regarding a purchase or response
        to a question or comment or because you have chosen to receive
        promotional, legal, or reminder emails about your contact lenses. We
        will communicate with you by e-mail or by posting notices on this
        Website. You agree that all agreements, notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing.
      </p>
      <h5>Terms and Conditions</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce massa
        orci enim vitae quis. Netus est massa ipsum mauris viverra donec id.
        Pretium est egestas risus sed. Neque lectus vitae commodo in magna
        sagittis sit placerat at. Morbi vel urna, quis mattis. Dui adipiscing in
        mi sed. A sed ut quis vivamus sem.
      </p>
      <ul>
        <li>
          <strong>Lensbooking LLC”</strong>maintains the www.lensbooking.com
          Website (“Site”).
        </li>
        <li>
          “United Arab of Emirates is our country of domicile” and stipulate
          that the<strong>governing</strong> law is the local law.
        </li>
        <li>
          “Any purchase, dispute or claim arising out of or in connection with
          this website shall be governed and construed in accordance with the
          laws of UAE”
        </li>
        <li>
          ‘’Visa or MasterCard debit and credit cards in
          <strong>AED</strong> will be accepted for payment’’
        </li>
        <li>
          ‘’The displayed price and currency at the checkout page, will be the
          same price and currency printed on the Transaction Receipt and the
          amount charged to the card will be shown in your card currency”.
        </li>
        <li>
          ‘’We will not trade with or provide any services to
          <strong>OFAC and sanctioned</strong> countries’’
        </li>
        <li>
          ‘’Customer using the website who are Minor /under the
          <strong>age of 18</strong> shall not register as a User of the website
          and shall not transact on or use the website’’
        </li>
        <li>
          ‘’Cardholder must retain a copy of
          <strong>transaction records</strong> and www.lensbooking.com policies
          and rules’’
        </li>
        <li>
          ‘’User is responsible for maintaining the
          <strong>confidentiality</strong> of his account’’
        </li>
      </ul>
      <h5>1. The validity of the General Conditions</h5>
      <p>
        All our offers, products, and services are based exclusively on the
        following General Terms and Conditions, even when accessed from outside
        the United Arab Emirates.
      </p>
      <p>
        Terms and Conditions that deviate from or are contrary to these General
        Terms and Conditions will not be recognized by the seller and shall not
        apply.
      </p>
      <h5>2. Conclusion of Agreement</h5>
      <p>
        2.1 All goods displayed on Lensbooking.com (the ‘website’) including
        prices quoted are non-binding and subject to change. All prices quoted
        contain the statutory value-added tax of the United Arab Emirates. Upon
        customer request, we have integrated each customer countries taxes into
        the website Lensbooking.com. The shown tax covers all taxes charged
        during the import to the customer’s country.
      </p>
      <p>
        2.2 Despite our greatest care not to do so, we are permitted and retain
        the right to make mistakes, such as typing errors, technical or color
        deviations, and/or price modifications. We are entitled to contest the
        declaration of acceptance as defined in clause 2.4 of this agreement in
        all such cases. We also assume no liability whatsoever as to the
        accuracy of the manufacturer’s description.
      </p>
      <p>
        2.3 Once you (the “CustomeBuyer”) have ordered any of our products, you
        are legally bound to these terms and conditions and have made an offer
        to buy the goods ordered.
      </p>
      <p>
        2.4 This (“Agreement”) becomes officially effective as soon as you have
        received our confirmation of your order.
      </p>
      <p>
        2.5 If the agreement has been entered into, but we are unable to deliver
        the ordered articles within the near future despite congruent hedging
        transactions, we reserve the right to withdraw from the agreement. If we
        should establish that delivery of goods might be delayed for an
        unforeseeable amount of time, we will advise you of this fact and refund
        any payments already made by you.
      </p>
      <h5>3. Reservation of Ownership</h5>
      <p>
        3.1 All goods shall remain Lensbooking.com property until all your
        contractual obligations towards us have been fulfilled.
      </p>
      <p>
        3.2 If you behave in any manner contrary to the terms of this agreement
        whatsoever, particularly by delaying payment, or by breach of agreement
        as stated in clause 3.1, we may without prejudice to our other rights,
        demand the immediate return of our property.
      </p>
      <h5>4. Placing an Order</h5>
      <p>
        4.1 If any goods ordered are not available, Lensbooking.com may offer
        you substitute products of equivalent quality and price. If we are
        unable to offer substitutes or you do not agree to accept such
        substitutes Lensbooking.com shall reimburse your payment within 10 days
        of the date Lensbooking.com receives your order.
      </p>
      <p>
        4.2 Once the payment is made, the confirmation notice will be sent to
        the client via email within 24 hours of receipt of payment. You can view
        the status of your order in the Your Account section in Lensbooking.com
      </p>
      <p>
        4.3 You confirm that to your acknowledgment, the contact lenses you
        order are of the correct power, desired color, and brand type.
      </p>
      <h5>5. Delivery, Shipping, and Passing of Risk</h5>
      <p>
        5.1 When you have submitted an order, you will receive an email from us
        acknowledging that we have received your order and confirming the
        details of your order, including the full shipping address and your
        order number. Once the order is ready to be dispatched, you will receive
        a shipment confirmation email with a tracking number.We deliver our
        Products to all Countries and mainly UAE. Shipping will be done by a
        third-party courier. Delivery within UAE shall be in 2-3 days , KSA and
        other GCC countries 5-7 days , outside GCC 14 days to 1 month depends on
        the country. UAE Delivery Fees will be free and Delivery Fees outside
        UAE will be mentioned on the checkout page depending on the country and
        location. Avoid any delivery delay by providing your full address along
        with your contact details.
      </p>
      <p>5.2 We provide worldwide shipping,</p>
      <p>
        5.3 Depending upon availability, all goods are dispatched as soon as
        possible. Should the requested item be out of stock, we will ensure that
        it is delivered as soon as possible (depending on whether the
        manufacturer has it in stock). Each delivery is accepted under reserve
        that we will be supplied in due time and form. Delivery time for custom
        or special orders may take up to 4-6 weeks.
      </p>
      <p>
        5.4 Any delivery dates or periods agreed upon, as legally binding must
        be written.
      </p>
      <p>
        5.5 In case of goods that cannot be delivered for reasons beyond our
        control, we will deem the agreement as having been fulfilled, if the
        items have been provided and the buyer notified. Delays due to cases of
        force majeure, disruption of transport or operative failures, strikes,
        shortage of raw materials and such can lead to a reasonable extension of
        the delivery time. If delays continue beyond 4 weeks after the
        conclusion of the agreement, then each party is legally entitled to
        withdraw from this agreement.
      </p>
      <p>
        5.6 We are entitled to carry out partial deliveries at our own cost for
        any ordered items that are out of stock, in as far as this is deemed
        reasonable for the buyer.
      </p>
      <p>
        5.7 Lensbooking.com shall bear the risk of any damage, theft, or loss of
        items during transport to the delivery address. This risk will be passed
        to the customer the moment he/she receives the delivery. Upon delivery,
        the customer should inspect the items to check for damage. If there is
        any damage, the product should not be used and should instead return as
        set out in the Returns Policy.
      </p>
      <p>
        5.8 Should the content of the package be opened, damaged, or stolen, a
        replacement for the same product including prescription power as ordered
        according to the order confirmation will be provided after receiving a
        valid report from the courier company.
      </p>
      <h5>6. Information Concerning the Exercise of the Right of Withdrawal</h5>
      <p>
        6.1 You have the right to withdraw from this agreement within 14 days
        without giving any reason.
      </p>
      <p>To exercise the right of withdrawal, you must inform us.</p>
      <p>
        6.2In the case of delivery of contact lenses and care products the
        consumer loses his right of withdrawal when unsealing the product.
      </p>
      <p>
        6.3 Once we have received the returned merchandise in sealed condition,
        we will refund the original purchase, minus any outstanding payments the
        customer may owe us.
      </p>
      <p>
        6.4 The customer will be responsible to pay for trackable shipment for
        return shipments. We reserve the right to charge for freight costs for
        the return shipment in case it is paid for by us.
      </p>
      <p>
        6.5 In case of loss of a return shipment without tracking information,
        Lensbooking.com shall be free of any claim for the unsuccessful
        transportation of the returned products.
      </p>
      <h5>7. Shipping Costs of Cancelled Merchandise</h5>
      <p>
        The customer will be liable for the shipping costs of any unsuccessful
        delivery up to a maximum amount of 40 USD.
      </p>
      <h5>8. Warranty</h5>
      <p>
        8.1 Should the delivered merchandise be defective, or its warranted
        qualities flawed, or should it develop defects within the warranty
        period as a result of poor materials or faulty manufacturing, then we
        will either repair the items or provide you with a replacement of the
        exact item including prescription power as ordered according to the
        order confirmation will be provided after receiving a valid report from
        the courier company. We are permitted to make multiple reparations.
      </p>
      <p>
        8.2 The warranty period begins with the date of delivery. Any damage
        must be reported within 10 days of the delivery of the item.
      </p>
      <p>
        8.3 All claims must be made in writing by contacting us, and any defects
        communicated to us immediately as soon as they become apparent.
      </p>
      <p>
        8.4 Upon discovery of any obvious deficiencies, and to prevent any
        further deterioration of the merchandise, you are obliged to inform us
        immediately. Failure to notify us of defects in good time will not
        influence our existing scope of a warranty. Defective merchandise must
        be returned to us for inspection in the same condition at the time that
        it was discovered as being faulty.
      </p>
      <h5>9. Limitation of Liability</h5>
      <p>
        We are exempt from being held liable for minor negligent breaches of
        duty as well as a bodily injury incurred during the wearing or insertion
        or removal of provided contact lenses, if they do not result in death,
        or any breach of the statutory implied terms as to the title of goods or
        claims according to the Product Liability Act. Furthermore, legal
        liability for breach of duties, which enables us to fulfill this
        agreement in the first place and in which the customer is entitled to
        trust, remains unaffected. The same applies to any breaches of duty by
        our vicarious agents. We accept no liability for incorrect use or
        improper handling of contact lenses supplied by Lensbooking.com
      </p>
      <h5>10 Prescription for USA Orders</h5>
      <p>
        Lensbooking.com provides contact lenses with Powers and Plano
        (non-powered contact lenses). If you are a US resident and wish to order
        a prescription contact lens with power, as per the FTCLCA (Fairness to
        Contact Lens Consumer Act), we need to verify your prescription before
        shipping your lenses. The verification can be done once you have placed
        your order, by filling in the Prescription Form. We reserve the right to
        contact your eye care provider regarding any issues with your
        prescription.
      </p>
      <h5>11. Payment</h5>
      <p>
        11.1 We reserve the right to specify which form of payment you may use.
        This applies in particular, to customers ordering for the first time, or
        orders where we have been unable to establish the buyer’s credit rating.
      </p>
      <p>
        11.2 We are entitled to initially offset any payments you make against
        any outstanding claims you may still have.
      </p>
      <p>
        11.3 For your part, you are only allowed to set-off costs, if your
        counterclaim is uncontested, or has been established as legally
        effective or expressly acknowledged in writing by us. You can only
        exercise your right of retention if your claim has resulted from the
        same contractual agreement.
      </p>
      <p>
        11.4 You can choose the following methods of payment in accordance with
        clause 10.1: Payment on delivery / Cash on delivery. Limited to orders
        within the GCC region: United Arab Emirates, Qatar, Oman, Kuwait,
        Bahrain, and Saudi Arabia.
      </p>
      <p>
        11.5 Payments by credit card will be debited from your account after the
        declaration of acceptance has been transmitted.
      </p>
      <h5>12. Data Protection</h5>
      <p>
        When ordering please pay special attention to the following information,
        as we only deliver contact lenses under the following conditions:
      </p>
      <p>
        13.1 The contact lens specification transmitted in your order is valid
        and corresponds to the most recent fitting carried out by a registered
        optician or ophthalmic medical practitioner and is no more than one year
        old. Optimal use of contact lenses is assured if the wearer is familiar
        with the care and handling of contact lenses and regularly takes part in
        the necessary ophthalmological examinations, to prevent any damage to
        his or her eyes.
      </p>
      <p>
        13.2 We explicitly request that contact lens wearers should have their
        eyes examined at least every six months. If the wearer has changed their
        brand, he or she should have the fit checked carefully by an
        ophthalmologist or a contact lens specialist. Regarding risks and side
        effects, please consult an ophthalmologist or contact lens specialist
        and make sure you read the information enclosed in the package.
      </p>
      <p>
        13.3 The respective manufacturer is exclusively responsible for the
        compatibility of the delivered products. Merchandise is delivered in the
        manufacturer’s original, unopened factory packaging. We accept no
        liability for incorrect use or improper handling. The stated shelf-life
        of contact lenses is based on the average amount of time that they would
        be worn. In order to ensure the good health of your eyes, we suggest
        that the recommended wearing period must not be exceeded. Should you
        develop any form of ocular irritation, please remove the contact lenses
        immediately and contact an ophthalmologist or eye specialist.
      </p>
      <h5>14. Place of Fulfillment</h5>
      <p>
        The place of fulfillment is the head office of Lensbooking.com The law
        of the United Arab Emirates applies exclusively to all legal
        relationships between the seller and the buyer and excludes the UN Sales
        Law.
      </p>
      <h5>15. Place of Jurisdiction, Severability Clause</h5>
      <p>
        15.1 If a buyer is a trader as defined by the UAE commercial law, a
        legal entity under public law, or a special fund under public law. Then
        the seller’s place of business shall be the agreed place of
        jurisdiction. The same applies if the customer has no general place of
        jurisdiction or no fixed residence in the UAE, or if his or her usual
        domicile is unknown at the time of filing an action.
      </p>
      <p>
        15.1 If a buyer is a trader as defined by the UAE commercial law, a
        legal entity under public law, or a special fund under public law. Then
        the seller’s place of business shall be the agreed place of
        jurisdiction. The same applies if the customer has no general place of
        jurisdiction or no fixed residence in the UAE, or if his or her usual
        domicile is unknown at the time of filing an action.
      </p>
      <p>We hope this has answered all open questions about purchasing any products from Lensbooking.com</p>
      <p>If you have any comments or concerns about material, which appears on our Website, please contact us.</p>
    </div>
  </div>
</div>
