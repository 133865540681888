import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-page',
  templateUrl: './seo-page.component.html',
  styleUrls: ['./seo-page.component.scss']
})
export class SeoPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  isReadMore = true

  showText() {
     this.isReadMore = !this.isReadMore
  }

}
