<section class="book-an-appointment-wrapper">
  <div class="container">
    <div class="row pt-md-5 mb-3">
      <div class="col-md-9">
        <h2 class="text-md-left text-center">Book An Appointment Today</h2>
        <mat-card class="demo-inline-calendar-card">
          <mat-calendar #calendar
            [minDate]="date"
            [startAt]="selected"
            [(selected)]="selected"
            (selectedChange)="onSelect($event)"
          ></mat-calendar>
        </mat-card>
      </div>
      <div class="appointment-left-view col-md-3 mt-4 mt-md-0">
        <!-- <h2 class="text-md-left text-center">Available Timings</h2> -->
        <!-- <div class="time-wrapper mb-3">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="9:30 AM"
                    (change)="handleTimeSelect($event.value)"
                    >9:30 AM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="10:30 AM"
                    (change)="handleTimeSelect($event.value)"
                    >10:30 AM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="11:30 AM"
                    (change)="handleTimeSelect($event.value)"
                    >11:30 AM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="12:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >12:30 PM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="1:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >1:30 PM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="2:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >2:30 PM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="3:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >3:30 PM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="4:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >4:30 PM</mat-button-toggle
                  >
                </div>
                <div class="col-md-3 col-6">
                  <mat-button-toggle
                    value="5:30 PM"
                    (change)="handleTimeSelect($event.value)"
                    >5:30 PM</mat-button-toggle
                  >
                </div>
              </div>
            </div>
          </mat-button-toggle-group>
        </div> -->
        <!-- <div *ngIf="selected && time" class="book-btn text-center mt-4 mb-4">
          <button type="submit" (click)="onBookNowClick()">Book Now</button>
        </div> -->
        <div class="book-btn text-center mt-4 mb-4">
          <button type="submit" (click)="onBookNowClick()">Book Now</button>
        </div>
      </div>
    </div>
  </div>
</section>
