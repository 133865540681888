<div class="contact-lense-wrapper">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Contact Lenses</h1>
      <p>
        Recent years have seen remarkable progress in contact lens fitting, design, and
        materials. VISIQUE is devoted to delivering the most advanced contact lens
        technology from top global manufacturers, incorporating the latest design
        innovations.
      </p>
      <p></p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading">
      <span>Contact lenses</span>
    </h1> -->
    <div class="card-wrapper raw">
      <!-- <div class="image-wrapper">
        <img
          class="card-image"
          src="../../../assets/images/contact-lenses.png"
          alt=""
        />
      </div> -->
      <div class="col-md-8">
        <div class="card-description mb-3">
          VISIQUE is dedicated to offering the latest contact lens technology from top
          international manufacturers, featuring the most up-to-date designs. Our
          specialized optometrists provide exceptional expertise in fitting advanced contact
          lenses.
        </div>
        <h6>
          We offer a wide range of contact lenses, including:
        </h6>
        <div>
          <div>
            <ul>
              <li>Toric lenses for astigmatism</li>
              <li>Multifocal lenses for presbyopia</li>
              <li>Daily disposables</li>
              <li>Cosmetic colored contact lenses</li>
              <li>Lenses for extremely high prescription</li>
              <li>Rigid gas permeable lenses in various designs</li>
              <li>Lenses for post-surgical issues</li>
              <li>Contact lenses for keratoconus</li>
            </ul>
          </div>
        </div>
      </div>
      <img 
        class="card-image col-md-4"
        src="../../../assets/images/contact-lenses.png"
        alt=""
      />
    </div>
    <!-- <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div> -->
  </div>
</div>
