import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { HelperService } from 'src/app/_services/helper.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm : FormGroup;
  constructor(private formBuilder: FormBuilder,private _api:ApiService, private _helper:HelperService) { }

  ngOnInit(): void {
    let emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    let MOBILE_PATTERN = '^[0-9]{10}$';

    this.contactForm = this.formBuilder.group({
      firstName : ['',[Validators.required, Validators.maxLength(50),Validators.pattern('^[A-Za-z ]*$')]],
      lastName  : ['',[Validators.required, Validators.maxLength(50),Validators.pattern('^[A-Za-z ]*$')]],
      phoneNo   : ['',[ Validators.required, Validators.pattern(MOBILE_PATTERN)]],
      emailId   : ['',[Validators.required, Validators.pattern(emailRegex)]],
      message   : ['',Validators.required]
    })
  }


  onContactFormSubmit(){
    if(this.contactForm.valid){
      const formData = {
        data:{
          firstName : this.contactForm.value.firstName,
        lastName  : this.contactForm.value.lastName,
        phoneNo   : this.contactForm.value.phoneNo,
        emailId   : this.contactForm.value.emailId,
        message   : this.contactForm.value.message
        }
      }
      this._api.POST('api/contact-us-forms',formData).subscribe((result:any) => {
        console.log('success');
        this._helper.showToast(1,'We will contact you soon..!')
        this.contactForm.reset();
      })
      
    }
    else{
      this.contactForm.markAllAsTouched();
    }
  }
}
