<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Meet Liliane Tanal OD., M.SC, Founder of VISIQUE
        Optometrists</h1>
      <p>
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Meet Liliane Tanal OD., M.SC, Founder of VISIQUE Optometrists</span>
    </h1> -->
    <div class="card-container">
      <div class="main-block row mb-3">
        <div class="col-md-8">
          <div class="card-description">
            <p>
              Liliane Tanal is a Doctor of Optometry who graduated from the School of
              Optometry at Université de Montréal in 1997. She also holds a Master’s degree in
              Corneal Physiology from the same institution. Specializing in keratoconus and
              abnormal corneal pathologies, Liliane&#39;s extensive experience is particularly
              valuable in our region, where these conditions are highly prevalent.
            </p>
            <p>
              Dr. Tanal worked as a clinician in the contact lens department at Université de
              Montréal and has been an invited speaker at numerous ophthalmic conferences
              and meetings across the region. Today, she is the founder and CEO of VISIQUE
              Optometrists, with a vision to establish optical boutiques in the Middle East that
              deliver the highest level of professional and refined eye care services, ensuring
              exceptional patient care and satisfaction.
            </p>
          </div>
        </div>
        <div class="col-md-4 right-content">
          <img
            src="../../../assets/images/liliane.png"
            alt="newsletter-image"
          />
          <p class="post">Founder & CEO</p>
        </div>
      </div>

      
    </div>
    <!-- <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div> -->
  </div>
</div>
