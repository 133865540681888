import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  Renderer2,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from '../_services/helper.service';
import * as JQuery from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../_services/api.service';

declare var $: any;
declare const window: any;

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  classApplied = false;
  classProfile = false;
  isOpened = false;
  bookData: any;

  date = new Date();
  selected: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _helper: HelperService,
  ) {}

  ngOnInit() {
    
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  onSelect(value: any) {
    this.selected = value;
    this._helper.setbookData({ date: this.selected });
    this.openEyeCareNavHide();
    this.router.navigateByUrl('/book-an-appointment');
  }
  openAboutNavHide() {
    JQuery('.open-about:hover .top-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('.open-about .top-megadropdown-wrapper').removeAttr('style');
    }, 1000);
  }
  openShopNavHide() {
    JQuery('.open-shop:hover .top-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('.open-shop .top-megadropdown-wrapper').removeAttr('style');
    }, 1000);
  }
  openEyeCareNavHide() {
    JQuery('li:hover .top-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('li .top-megadropdown-wrapper').removeAttr('style');
    });
  }
  openServicesNavHide() {
    JQuery('.open-services:hover .bottom-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('.open-services .bottom-megadropdown-wrapper').removeAttr('style');
    });
  }
  openSpecialitiesNavHide() {
    JQuery('.open-specialities:hover .bottom-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('.open-specialities .bottom-megadropdown-wrapper').removeAttr(
        'style'
      );
    });
  }
  openKidsCornerNavHide() {
    JQuery('.open-kids-corner:hover .bottom-megadropdown-wrapper').attr(
      'style',
      'display:none !important'
    );
    setTimeout(() => {
      JQuery('.open-kids-corner .bottom-megadropdown-wrapper').removeAttr(
        'style'
      );
    });
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    $(window).scroll(function () {
      const sticky = $('.visique-header-wrapper'),
        scroll = $(window).scrollTop();
      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });

    window.addEventListener('load', function () {
      const sticky = $('.visique-header-wrapper'),
        scroll = $(window).scrollTop();

      if (scroll > 10) sticky.addClass('scrolled_navbar');
      else sticky.removeClass('scrolled_navbar');
    });
  }

  showPopUp() {
    const dialogRef = this.dialog.open(NavbarPopUp,{
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'navbar-popup',
  templateUrl: './navbar-popup.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarPopUp implements OnInit {
  ngOnInit(): void {
    let emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public  subscribeForm: FormGroup,
    private router: Router,
    private dialog: MatDialogRef<NavbarPopUp>,
    private _helper: HelperService,
    private formBuilder: FormBuilder,
    private _api : ApiService,
  ) {
  }

  closeModal() {
    this.dialog.close({});
  }

  onSubscribe(){
    if(this.subscribeForm.valid){
      let email = this.subscribeForm.value.email;
      console.log(email);
      this._api.POST(`api/visique-pop-up-subscribes`,{data:{email}}).subscribe((result:any) => {
        this._helper.showToast(1,'We will contact you soon..!')
        this.subscribeForm.reset();
      })
    }
    else{
      this.subscribeForm.markAllAsTouched();
    }
  }
}
