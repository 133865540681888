<div class="container">
  <div class="card-container">
    <h1 class="section-heading text-center">
      <span>Testimonials</span>
    </h1>

    <!-- <div class="card-wrapper">
      <div *ngFor="let item of content">
        <div routerLink="/testimonial"
          class="singleCard"
          [ngStyle]="{
            'background-image': 'url(' + item.url + ')'
          }"
        >
          <div class="card-content">
            <h1 class="card-heading">
              <span> {{ item.description }}</span>
            </h1>
          </div>
        </div>
      </div>
    </div> -->

    <div class="cards col-md-12 flex-row d-flex justify-content-between">
      <div class="coloumn col-md-4">
        <div class="card-head">
          <!-- <img
            src="../../../assets/images/testimonials-image1.png"
            alt="testimonials-image1"
          /> -->
          <h4>Roby 86</h4>
          <!-- <h5>Owner - Mzannar Jewerly</h5> -->
        </div>
        <div class="card-desp">
          <div class="testimonial-content">
            <p>“Amazing and professional staff, in pursue for finding the best eyeglass for your 
              needs and look. Great experience and can definitely recommend. 👍🏻”</p><br>
              <a routerLink="/testimonial">Read More</a>
          </div>
        </div>
      </div>
      <div class="coloumn col-md-4">
        <div class="card-head">
          <!-- <img
            src="../../../assets/images/testimonials-image2.png"
            alt="testimonials-image2"
          /> -->
          <h4>A K</h4>
          <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
        </div>
        <div class="card-desp">
          <div class="testimonial-content">
            <p>“Friendly, well informed and really took their time with us.
              Frame selection is one of the most well thought out collections in Dubai.”</p><br>
              <a routerLink="/testimonial">Read More</a>
          </div>
        </div>
      </div>
      <div class="coloumn col-md-4">
        <div class="card-head">
          <!-- <img
            src="../../../assets/images/testimonials-image3.png"
            alt="testimonials-image1"
          /> -->
          <h4>Kamal Shehadi</h4>
          <!-- <h5>Owner - Mzannar Jewerly</h5> -->
        </div>
        <div class="card-desp">
          <div class="testimonial-content">
            <p>“The most professional optometrist in Dubai and the best advice. They’re not about 
              selling you frames and lenses; they’re about serving the patient.”</p><br>
              <a routerLink="/testimonial">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
