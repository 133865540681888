<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Unmatched Portfolio of Specialized Contact lenses</h1>
      <p>
        Contact lenses can enhance both your appearance and visual performance.
        Advances in optometry have led to a diverse range of contact lenses, each
        offering unique benefits.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card-container">
      <div class="row mb-3">
      </div>
      <div class="card-description">
        <h2>
          Unsure whether glasses or contact lenses are better for you? Explore the
          unique benefits of contact lenses and see how they compare.
        </h2>
        <p>
          After conducting a comprehensive eye exam to assess the health of your
          eyes, your optometrist will determine if you need vision correction. Often,
          this means that regular or specialty contact lenses may offer better vision
          and comfort compared to eyeglasses. If you have conditions such as
          astigmatism, myopia, keratoconus, presbyopia, dry eyes, or poor vision,
          specialty contact lenses might be recommended. Contact lenses can
          enhance both your appearance and visual performance, thanks to advances
          in optometry that have led to a diverse range of lenses, each with its own
          benefits.
        </p>
      </div>
    </div>
  </div>
  <div class="contact-lens-card mt-5 ml-5 mr-5">
    <ul>
      <li><b>Single Vision Disposables (Spherical Correction):</b> When compared to hydrogel soft
        contact lenses, silicone hydrogel lenses supply three to five times more oxygen to the
        cornea.</li>
      <li><b>Disposables ( Astigmatism Correction)</b></li>
      <li><b>Presbyopia Disposables (Far & Near Vision)</b></li>
      <li><b>High Prescriptions Disposables:</b> Rigid Gas Permeable Lenses are available in a variety of
        designs. Fitting for Keratoconus, Surgical Complications, and Corneal Ring Complications</li>
      <li><b>Cosmetic Color Contact Lenses:</b> Buying cosmetic contact lenses from unapproved
        sources can be very risky and may lead to serious eye problems. It’s important to ensure
        the quality of the lenses and have a specialist evaluate them before purchasing.</li>
    </ul>
  </div>
  <div class="container-fluid mt-5 mb-5">
    <div class="faq-wrapper">
      <div class="logo-header">
        <img src="../../../assets/images/faq-frame-image.png" alt="" />
        <h2>Frequently Asked Questions</h2>
      </div>
      <div class="specialities-faq">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How do conventional contact lenses differ from disposable contacts?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <p>
                Conventional contact lenses are usually worn during the day for a month, then
                cleaned and stored overnight to be used again the next day. Disposable
                contact lenses are meant for either single-day or extended use: you wear them
                for the day and throw them away at night, using a new pair the next morning.
              </p>
            </mat-panel-description>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How is specialty contact lens fitting done?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <p>At VISIQUE, specialty contact lens fitting includes precise eye measurements,
                selecting and testing trial lenses, and fine-tuning the fit to ensure optimal
                comfort and visual performance.</p>
            </mat-panel-description>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Does VISIQUE offer appointments for specialty contact lens fitting?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <p>
                Given that some eye conditions require precise and time intensive fitting, you
                can book an appointment with our optometrists at VISIQUE to ensure your
                contact lenses are properly fitted and tailored to your needs.  
              </p>
            </mat-panel-description>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Are there any special contact lenses for dry eyes?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <p>
                Many contact lenses help with dry eyes, and your optometrist can help you
                choose the best one. Scleral lenses are especially effective for dry eyes
                because they sit on the white part of the eye, not the cornea, creating a space
                filled with saline that keeps your eyes hydrated. 
              </p>
            </mat-panel-description>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
