<div class="container-fluid">
    <h1 class="section-heading">
        <span>Collections</span>
    </h1>
    <div class="card-wrapper">
        <div class="row">
            <div class="col-md-4">
                <a><img src="../../../assets/images/Burberry-new.png" alt=""></a>
                <a><h6>Burberry</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/Cartier-new.png" alt=""></a>
                <a><h6>Cartier</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/D&G-new.jpg" alt=""></a>
                <a><h6>D&G</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/bvlgari-new.jpg" alt=""></a>
                <a><h6>BVLGARI</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/emporioarmani-new.jpg" alt=""></a>
                <a><h6>Emporio Armani</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/garettleight-new.jpg" alt=""></a>
                <a><h6>Garret Leight</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/giorgioarmani-new.jpg" alt=""></a>
                <a><h6>Giorgio Armani</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/gucci-new.png" alt=""></a>
                <a><h6>Gucci</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/lindberg-new.jpg" alt=""></a>
                <a><h6>LINDBERG</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/lafont-new.jpg" alt=""></a>
                <a><h6>Lafont</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/mauijim-new.jpg" alt=""></a>
                <a><h6>Maui jim</h6></a>
            </div>
            <div class="col-md-4">
                <a><img src="../../../assets/images/mykita-new.jpg" alt=""></a>
                <a><h6>Mykita</h6></a>
            </div>
        </div>
    </div>
</div>
