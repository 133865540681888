<div class="container-fluid">
  <h1 class="section-heading text-center">
    <span>Blog</span>
  </h1>
  <h6 class="section-subheading">Most Recent</h6>
  <div class="card-wrapper">
    <div class="image-wrapper mb-3">
      <img src="../../../assets/images/blog-main.png" alt="" />
      <h6 class="image-meta">21 Jan 2022</h6>
    </div>
    <div class="card-description">
      <h5>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis.
      </h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis. Tempor, fames scelerisque enim elit quis semper.
        Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam ac lectus.
        Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices in id felis
        tincidunt volutpat ultrices dictumst.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis. Tempor, fames scelerisque enim elit quis semper.
        Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam ac lectus.
        Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices in id felis
        tincidunt volutpat ultrices dictumst.
      </p>
    </div>
  </div>
  <div class="sub-card-wrapper">
    <div class="image-wrapper">
      <img src="../../../assets/images/blog-sub.png" alt="" />
    </div>
    <div class="card-contents">
      <h6>21 Jan 2022</h6>
      <h5>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus ?
      </h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis. Tempor, fames scelerisque enim elit quis semper.
        Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam ac lectus.
        Aliquet lacinia morbi dictumst mi.
      </p>
    </div>
  </div>
  <div class="sub-card-wrapper">
    <div class="image-wrapper">
      <img src="../../../assets/images/blog-sub.png" alt="" />
    </div>
    <div class="card-contents">
      <h6>21 Jan 2022</h6>
      <h5>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus ?
      </h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis. Tempor, fames scelerisque enim elit quis semper.
        Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam ac lectus.
        Aliquet lacinia morbi dictumst mi.
      </p>
    </div>
  </div>
</div>
