<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Services of Our Ophthalmologist in Dubai and Lenses</h1>
      <p>
        No matter what your refraction is, there are now prescription lenses
        that meet your vision correction needs and your lifestyle.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Ophthalmic lenses</span>
    </h1> -->
    <div class="card-container d-flex flex-row justify-content-between col-md-12">
      <div class="card-description col-md-7">
        <!-- <h1>Services of Our Ophthalmologist in Dubai and Lenses</h1>
        <p>
          No matter what your refraction is, there are now prescription lenses
          that meet your vision correction needs and your lifestyle.
        </p> -->
        <p>
          We only work with internationally recognized ophthalmic lenses. Our
          team of <strong>ophthalmologists in Dubai</strong> is always
          up-to-date with the latest ophthalmic lenses technology in order to
          prescribe for you the right eyeglasses that suit your eyes as a high
          index to get the thinnest lenses, photochromic lenses that change as
          the light change, blue filter anti-reflection for the computer use and
          progressive lenses that give you the best vision at all distances if
          you are presbyopic.
        </p>
        <h1>Recommended Lenses by Ophthalmologist Dubai</h1>
        <p>
          A thinner manufacture at the edges of the lens and lighter in weight a
          good choice for people with a stronger prescription. These are lenses
          that have a higher index of refraction, enabling them to bend light
          rays to create a clearer vision for these strong prescription glasses.
          It is especially a good option for people having Myopia, or
          nearsightedness, since they have difficulty seeing objects at a
          distance.
        </p>
        <h3>Specialized Kids Lenses</h3>
        <p>
          Specialized kids’ lenses are more impact resistant than regular
          plastic lenses. VISIQUE Optometrists' kids’ eyewear frames are more
          durable, thinner, lighter, and made of stronger materials, such as
          stainless steel, acetate, and titanium. This allows the kids to have
          as much of a childhood experience without worrying about their vision,
          or their glasses constantly breaking. There are also contact lenses
          for kids, which is proven by pediatric studies that they improve their
          activity participation more than when wearing glasses.
        </p>
        <h3>Progressive Lenses</h3>
        <p>
          Progressive lenses allow the wearer multiple vision fields to be
          incorporated into one single lens without any distinction between the
          fields of themselves. Since age affects vision most of the time,
          progressive lenses tackle different eye conditions instead of just
          focusing on one, where they help see clearer from all distances. You
          can see objects clearly at a distance using the top part of the lens,
          and the bottom to see better nearsightedness.
        </p>
        <h3>Light Protection Lenses</h3>
        <p>
          There are various types of light protection lenses that you can choose
          from, and that depends on the spectacle use, and if their use is
          limited to indoor use or outdoors as well.
        </p>
        <h4>-Lens tints</h4>
        <p>
          Ophthalmic lenses have different colored tints and densities. People
          who are diagnosed with asthenopia, commonly known as eye strains,
          benefit more from 10% to 20% tinted lenses. These can be worn all day
          and create comfort, as well as reduce glare and UV protection.
        </p>
        <h4>-Blue blocking filters</h4>
        <p>
          These are standard light protection lenses that reduce visible light,
          in which it reduces glare and improves contrast vision. However, blue
          light glasses result in a higher risk for color distortion.
        </p>
        <h4>-Polarising filters</h4>
        <p>
          Polairising filtered lenses get rid of light reflections off of shiny surfaces like glass. 
          Less light passes through the filter when rays hit the lens at a perpendicular angle.
        </p>
        <h3>Photochromic Lenses</h3>
        <p>
          Photochromic lenses change from clear to dark based on the intensity
          of UV rays radiation. The eyeglasses lenses are clear when indoors,
          and when exposed to sunlight, they automatically darken. They can also
          be known as “light-adaptive lenses”.
        </p>
        <p>
          A technical explanation for that is based on the molecules found in
          these lenses, which are activated due to Ultraviolet radiation from
          the sun, causing them to darken.
        </p>
      </div>
      <div class="card-image col-md-5">
        <img src="../../../assets/images/OPHTHALMIC LENSES.jpg" alt="" />
      </div>
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What is an ophthalmologist and what do they differ from
                  opticians?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                An ophthalmologist specialist is considered to be a physician
                that specializes in eye care. They are different from other eye
                care providers based on their level of training.
                Ophthalmologists are required to finish about 12 years of
                education and training in order to get licensed to be able to
                practice medicine, treat and diagnose conditions, and perform
                surgeries.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is there a specialized ophthalmologist for kids?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                A pediatric ophthalmologist is an ophthalmologist specialist
                with the addition of training for pediatrics. Any eye problem,
                medical treatment, or eye surgery a child needs is referred to
                the pediatric ophthalmologist.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are the types of treatments that are provided by an
                  ophthalmologist specialist?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                An ophthalmologist can diagnose and treat eye problems. They can
                perform eye examinations, and surgeries (like microsurgery and
                laser surgery), diagnose problems related to the body that
                affect the eyes (like diabetes and Juvenile Rheumatoid Arthritis
                JRA), provide eye injury care, and can also prescribe eyeglasses
                or contact lenses.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Can I get ophthalmic lenses with no prescription from the
                  eyecare specialist?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It is necessary to get a medical prescription for ophthalmic
                lenses and it is recommended that you always consult with your
                eye care specialist or optometrist before deciding to use
                ophthalmic lenses and ask what works best for you.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are bifocal and trifocal lenses the same as progressive
                  lenses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Bifocal and trifocal lenses contain two or three magnification
                levels which are separated by a visible drawn line on the
                lenses. While progressive lenses give a gentle transition of the
                prescription difference without having this harsh line.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What does 6/6 stand for? </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                People with normal vision usually have a visual acuity of 6
                meters, so 6/6 is the standard for normal distance vision. If
                you have 6/6 vision, you can read letters at a distance of 6
                meters.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What is lens coating? </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Coating a lens reduces reflection from its surface and clears
                the vision. There are different types of lens coating, which
                include an anti-reflective coating that reduces the lenses’
                reflection and glare, hydrophobic coating that makes cleaning of
                the lenses easier and keeps it transparent with no smudges, and
                scratch-resistant coating that protects the ophthalmic lenses
                from scratches.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What affects my vision the most?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                The main stimulant of sight is light, and part of the light can
                be harmful. This includes invisible UV rays that are present in
                sunlight, which is classified into UVA and UVB which cause skin
                and eye damage. Additionally, spending consecutive hours on a
                digital device like TV, phone, laptop, computer, etc… causes
                fatigue and eyestrain.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
