import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {
  baseUrl = environment.apiUrl;
  sliderData : any;
  constructor(@Inject(PLATFORM_ID) private platform: Object,private _api:ApiService) { 
  }

  ngOnInit(): void {
    this.getSliderData();
  }


  getSliderData(){
    this._api.GET('api/visique-banners?populate=*&').subscribe((result:any) => {
      this.sliderData = result?.data;
      console.log(this.sliderData);
      
    })
  }

  goToBooking():void{
    if (isPlatformBrowser(this.platform)) {
      window.open('https://lensbooking.com/shop/',"_blank")
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    center: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots:false,
    autoplay: true,
    autoplaySpeed: 500,

    // dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
}
