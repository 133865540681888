<div class="privacy-policy-wrapper ml-5 mr-5">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Privacy Policy</span>
    </h1>
    <div class="card-wrapper">
      <p>
        Welcome to VISIQUE. This document is an electronic record in terms of
        Information Technology Act, 2000 and published in accordance with the
        provisions of Rule 3 ) of the Information Technology (Intermediaries
        guidelines) Rules, 2011 that require publish
      </p>
      <h5>Collection, processing, and use of personal data</h5>
      <p>
        To be able to provide you with the merchandise you order,
        Lensbooking.com exclusively collects, stores, and processes personal
        data following the standard, legally valid regulations as far as is
        required and deemed necessary for fulfilling the contractually agreed
        service obligations between Lensbooking.com and you, the customer. The
        term “personal data” includes all details that you enter on the website
        and enables personal identification (name, address, landline, and mobile
        phone numbers). Please also know that in handling your information, we
        do not sell your personal information.
      </p>
      <p>
        This Privacy Policy lets you know how we collect, use, share, and
        protect information about you when you interact with Lens Booking
        through our website, however, accessed, (“Website”) or mobile
        applications (“App”) (which we will refer to together as the “Site”) to
        use or obtain any of our products or services (the “Services”). Where we
        post this Privacy Policy, we explain what information we collect through
        the Site, how we use it, with whom we share it, and the choices you have
        to control, access, and update your information. If you have questions
        about anything in our Privacy Policy, please do not hesitate to contact
        us.
      </p>
      <h5>Information we collect</h5>
      <p>
        When you use the Lensbooking.com platform we will ask and collect the
        following information. The information is necessary for us to store to
        fulfill our services and deliver the correct product to you. It is only
        visible to yourself; those you choose to share it with and team members
        of this website that have the correct permissions to see it.
      </p>
      <h5>Account Information</h5>
      <p>When you sign up on Lensbooking.com, we will as you for your;</p>
      <ul>
        <li>
          <span>First name and last name– </span>We ask for these details to
          identify you when trying to communicate with you and to have some of
          your details in place before we send your product.
        </li>
        <li>
          <span>Email address– </span>This will serve as your username when you
          ever try to log into your account. This will be needed anytime you
          decide to log into your account. Your email address is never shared
          with anyone. Permission will be asked from you to contact you about
          news or promotions from this website.
        </li>
        <li>
          <span>Password –</span>This is required to log into your account. This
          will grant access to placing an order, viewing your order history, and
          amending your address details.
        </li>
      </ul>
      <h5>Placing an order – Information we will collect</h5>
      <p>
        When you decide to place an order from Lensbooking.com, in addition to
        the details above, we will ask for the following information to collect
        payment and fulfill the shipment services.
      </p>
      <ul>
        <li>
          <span>Telephone–</span> This is collected in the case the courier
          needs verification of your delivery location or in case, we would need
          to contact you about your product.
        </li>
        <li>
          <span>Address, City, Country–</span>These are mandatory fields, which
          are required to collect and pass onto the courier company to deliver
          your product.
        </li>
        <li>
          <span>Zip / Postal Code–</span>Optional field to support the address
          details.
        </li>
        <li>
          <span>State / Province –</span>Optional field to support the address
          details.
        </li>
      </ul>
      <h5>Collection Payment</h5>
      <p>
        To fulfill our duties and timely deliver your product, we will offer you
        several ways to make payment for goods ordered.
      </p>
      <ul>
        <li>
          <span>Debit / Credit Card–</span>At Lensbooking.com, none of your
          debit or credit card details are processed within our platform,
          therefore we do not collect or store any of your bank account
          information. It is required that an e-commerce platform uses an
          authorized payment gateway to collect payment from a customer’s debit
          or credit card. We use Checkout.com, which allows you to make payment
          using your Visa, MasterCard, or American Express card. System
          integration has been applied on Lensbooking.com from Checkout.com to
          collect the customer’s payment details. Lensbooking.com will not be
          accounted for or take any responsibility for how Checkout.com uses
          your data. ‘‘All credit/debit cards’ details and personally
          identifiable information will NOT be stored, sold, shared, rented or
          leased to any third parties’’ www.lensbooking.com will not pass any
          debit/credit card details to third parties’’ www.lensbooking.com takes
          appropriate steps to ensure data privacy and security including
          through various hardware and software methodologies. However
          www.lensbooking.com (website) cannot guarantee the security of any
          information that is disclosed online’’
        </li>
      </ul>
      <h5>Collection of non-personal data</h5>
      <p>
        To improve our range of products, Lensbooking.com uses “cookies”
        (compact data packages that contain configuration information). The data
        stored in these cookies does not permit you to be identified, and cannot
        be attributed to a particular individual. Cookies can be deactivated in
        your browser’s preferences.
      </p>
      <h5>Transmission of personal data</h5>
      <p>
        Where necessary, Lensbooking.com might pass on payment details to its
        principal bank to process payments. We may also pass on details of your
        shipping address to the company commissioned with the delivery of your
        order.
      </p>
      <p>
        Lensbooking.com is prohibited from transmitting or reselling customer
        data to third parties unless given express permission to do so by the
        customer.
      </p>
      <h5>Right to information</h5>
      <p>
        Following the data privacy protection act, each user is entitled to
        enquire about any personal data that has been stored and (where
        warranted) also has the right to demand that personal data be modified,
        blocked, or deleted free of charge. In this case, the user must contact
        us.
      </p>
      <h5>Cancellation of consent</h5>
      <p>
        Each user can withdraw their consent at any time with future effect and
        without giving a particular reason by contacting us.
      </p>
      <h5>Recruiting customers</h5>
      <p>
        As a Lensbooking.com customer, you may recruit friends, relatives, and
        acquaintances as new customers. Please bear in mind that you are only
        permitted to recruit people you know. Lensbooking.com will send an email
        to these people and will use the data provided by you exclusively for
        this purpose. In addition, please note that people recruited by you will
        receive an email with your first name and surname notifying them to that
        effect.
      </p>
      <h5>What we collect</h5>
      <p>We may collect the following information:</p>
      <ul>
        <li>Name</li>
        <li>Contact information including email address</li>
        <li>
          Demographic information such as postcode, preferences, and interests
        </li>
        <li>Other information relevant to customer surveys and/or offers</li>
      </ul>
      <h5>What we do with the information we gather</h5>
      <p>
        We require this information to understand your needs and provide you
        with a better service, and in particular for the following reasons:
      </p>
      <ul>
        <li>Internal record keeping.</li>
        <li>
          We may use the information to improve our products and services.
        </li>
        <li>
          We may periodically send promotional emails about new products,
          special offers, or other information, which we think you may find
          interesting using the email address, which you have provided.
        </li>
        <li>
          From time to time, we may also use your information to contact you for
          market research purposes. We may contact you by email, phone, or mail.
          We may use the information to customize the website according to your
          interests.
        </li>
        <li>
          For existing and aspirational social media partnerships, we might send
          out promotional product collections and gifts. If the products within
          the PR package are worn publicly, we reserve the right to use the
          resulting social media content within our own social media presence
          and refer to the customer or recipient. Any address or personal
          details will in no case be shared or published by Lensbooking.com.
        </li>
      </ul>
      <h5>Security</h5>
      <p>
        We are committed to ensuring that your information is secure. To prevent
        unauthorized access or disclosure, we have put in place suitable
        physical, electronic, and managerial procedures to safeguard and secure
        the information we collect online.
      </p>
      <h5>How we use cookies</h5>
      <p>
        cookie is a small file, which asks permission to be placed on your
        computer’s hard drive. Once you agree, the file is added, and the cookie
        helps analyze web traffic or lets you know when you visit a particular
        site. Cookies allow web applications to respond to you as an individual.
        The web application can tailor its operations to your needs, likes, and
        dislikes by gathering and remembering information about your
        preferences.
      </p>
      <p>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyze data about web page traffic and improve our website to
        tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </p>
      <p>
        Overall, cookies help us provide you with a better website, by enabling
        us to monitor which pages you find useful and which you do not. A cookie
        in no way gives us access to your computer or any information about you,
        other than the data you choose to share with us. You can choose to
        accept or decline cookies. Most web browsers automatically accept
        cookies, but you can usually modify your browser setting to decline
        cookies if you prefer. This may prevent you from taking full advantage
        of the website.
      </p>
      <h5>Links to other websites</h5>
      <p>
        Our website may contain links to other websites of interest. However,
        once you have used these links to leave our site, you should note that
        we do not have any control over that other website. Therefore, we cannot
        be responsible for the protection and privacy of any information, which
        you provide whilst visiting such sites and this privacy statement does
        not govern such sites. You should exercise caution and look at the
        privacy statement applicable to the website in question.
      </p>
      <h5>Controlling your personal information</h5>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <ul>
        <li>
          Whenever you are asked to fill in a form on the website, look for the
          box that you can click to indicate that you do not want the
          information to be used by anybody for direct marketing purposes
        </li>
        <li>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          contacting us.
        </li>
        <li>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties, which we think you may find
          interesting if you tell us that you wish this to happen.
        </li>
        <li>
          You may request details of personal information, which we hold about
          you under the Data Protection Act 1998.
        </li>
        <li>
          If you believe that, any information we are holding on to you is
          incorrect or incomplete, please write to or email us as soon as
          possible. We will promptly correct any information found to be
          incorrect.
        </li>
      </ul>
      <h5>Lensbooking.com Newsletters</h5>
      <p>
        We will ask for your permission to send you any communication via email
        that may contain news, offers, or trends. At the point of the customer
        accepting Lensbooking.com to send newsletters, the customer will become
        a subscriber.
      </p>
      <p>
        Subscribing to our newsletters will benefit you from receiving the
        latest news relating to a product you may have recently viewed or
        purchased.
      </p>
      <p>
        You can subscribe to receive newsletters when you sign up on
        Lensbooking.com when you are placing an order, by typing your email
        address and clicking on the ‘Subscribe’ button on the subscription box
        on Lensbooking.com homepage or by contacting us.
      </p>
      <h5>How to unsubscribe from receiving newsletters</h5>
      <p>
        If you wish to unsubscribe from our newsletter database, you can do this
        by clicking on the unsubscribe link on any emails we have sent you or by
        contacting us.
      </p>
      <p>
        You can unsubscribe at any time by clicking on the ‘Unsubscribe’ link at
        the bottom of any emails we have sent you.
      </p>
      <p>
        If you subscribe to our newsletter, we will use your email address (with
        your permission) for marketing purposes, until we receive your request
        to unsubscribe. You will only receive emails about the products that you
        have purchased or viewed. From time to time, we will also send you
        promotions with discount codes.
      </p>
      <p>
        Some of our email communication will be sent via third-party marketing
        software. This software recognizing the type of items and emails you
        would be interested in receiving.
      </p>
      <h5>Consent</h5>
      <p>
        Any individual who is under the age of 18 should get permission from
        their guardian who should be over the age of 18.
      </p>
    </div>
  </div>
</div>
