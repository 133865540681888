<div class="container">
  <div class="card-container">
    <h1 class="section-heading text-center">
      <span
        >We deliver your contact lenses<br />
        right to your doorstep
      </span>
    </h1>

    <div class="card-wrapper">
      <div *ngFor="let item of content">
        <div
          class="singleCard"
          [ngStyle]="{
            'background-color': item.background
          }"
        >
          <div class="card-content">
            <div class="image-wrapper">
              <img src="{{ item.url }}" alt="card-pic" />
            </div>

            <p class="card-heading">
              {{ item.capitalHeading }}
            </p>
            <p class="card-description">
              {{ item.description }}
            </p>
            <div *ngIf="item.buttonElement" class="more-button">
              <button *ngIf="!item.samePage" (click)="goToBooking(item.link)">{{ item.buttonText }}</button>
              <button *ngIf="item.samePage" routerLink="/collections">{{ item.buttonText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
