import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-liliane',
  templateUrl: './meet-liliane.component.html',
  styleUrls: ['./meet-liliane.component.scss']
})
export class MeetLilianeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
