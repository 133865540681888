<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Comprehensive Eye Refraction exam</h1>
      <p>
        Routine refraction eye exams can detect eye abnormalities before you even
        notice them. Make sure to schedule your exam and don’t delay getting checked!
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Comprehensive Eye Refraction exam</span>
    </h1> -->
    <div class="card-container">
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <img
            src="../../../assets/images/refraction-exam-new-main.png"
            alt="Comprehensive Eye Refraction exam"
          />
          <h5 class="image-meta">Comprehensive Eye Refraction exam</h5>
        </div>
        <!-- <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
        <h1>The Importance of an Eyecare Exam</h1>
        <p>
          In a comprehensive eye exam, our skilled optometrists do much more than just
          determine your eyeglass or contact lens prescription. They thoroughly assess the
          overall function of your eyes, checking for common conditions such as:
        </p>
        <ul>
          <li><strong>Nearsightedness (Myopia):</strong> Difficulty seeing distant objects clearly while close-up
            vision remains good.</li>
          <li><strong>Farsightedness (hyperopia):</strong> Difficulty seeing close objects clearly while distant
            vision is better.</li>
          <li><strong>Astigmatism:</strong> An abnormal curvature of the cornea that causes blurred vision at
            all distances, a common refractive error similar to myopia and hyperopia.</li>
          <li><strong>Binocular Deficiencies:</strong> Issues with how the eyes work together, affecting
            alignment, focusing, movement, and fixation.</li>
          <li><strong>Strabismus (Crossed Eyes):</strong> A condition where one or both eyes misalign, causing
            difficulties with eye coordination. This can result from poor control of the ocular
            muscles.</li>
          <li><strong>Amblyopia (Lazy Eye):</strong> Vision impairment in one eye that cannot be fully
            corrected with glasses or contact lenses, often developing in children under eight
            and requiring prompt treatment.</li>
          <li><strong>Keratoconus:</strong> A degenerative condition where the cornea becomes thin and cone-
            shaped, leading to distorted vision.</li>
        </ul>
        <p>
          Additionally, we offer the latest ophthalmic lens designs and coatings to enhance
          your vision and comfort. Our prescription lenses come in various types, including

          single vision, progressive, driving, anti-fatigue, and screen protective lenses,
          tailored to fit your lifestyle. We also provide fast repair and frame-cleaning
          services to keep your eyewear in top condition.
        </p>
      </div>
      <img class="section-img" src="../../../assets/images/Comprehensive-Eye-Refraction.png" alt="">
    </div>
    <h4 class="enquiry-check">
      Book an appointment to get your comprehensive eye exam done at VISIQUE.
      Those above the age of 14 years old can come to get checked.
    </h4>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Where is the VISIQUE
                  eye care center located?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  You can visit our optometrists at our eye care centers located in Dubai Hills Mall,
                  Yas Mall Abu Dhabi, and City Centre Al Zahia in Sharjah.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How Often Should I Visit an Eye Care Specialist?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  As an adult, it is recommended to visit eye care professionals at least once every
                  one to two years. Seniors aged 61 and above should have their eyes checked
                  annually.
                  <!-- <span routerLink="/book-an-appointment"
                    >here.</span> -->
                  
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I know if I currently have one of the eye conditions mentioned?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  After you complete an eye test at any VISIQUE Optometrists Boutique, our
                  professionals will thoroughly review your results and check for any eye
                  conditions.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What would I do if my comprehensive eye examination detected a condition?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  If your eye examination at a VISIQUE detects a condition, our professionals will
                  explain the findings and recommend treatments such as prescription eyeglasses,
                  contact lenses, or other appropriate solutions.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I know if I currently have one of the eye conditions
                  mentioned?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  Upon seeing an eye specialist in Dubai VISIQUE
                  Optometrists store and doing the comprehensive eye test, the
                  optometrist checks for any eye condition.
                </p>
              </mat-panel-description>
            </mat-expansion-panel> -->
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What would I do if my
                  comprehensive eye examination detected a
                  condition?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  VISIQUE Optometrists provides optical products such as medical
                  contact lenses and eyewear glasses to fit your preferences and
                  needs.
                </p>
              </mat-panel-description>
            </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
