<header class="visique-header-wrapper">
  <div class="offer-banner d-none d-md-block text-center">
    Benefit today from our LENSBYMAIL program and enjoy 2 months FREE contact lenses!
    <a (click)="showPopUp()">Subscribe Now</a>
  </div>
  <div class="container-fluid">
    <div class="header-flex">
      <div class="d-none d-md-block header-left">
        <ul class="navbar-c">
          <li class="open-home">
            <span routerLink="/">Home</span>
          </li>
          <li class="open-about" (click)="openAboutNavHide()">
            About
            <span>
              <img src="../../assets/images/dropdown-open.svg" alt="dropdown" />
            </span>
            <div class="top-megadropdown-wrapper">
              <div class="top-megadropdown-box">
                <div class="row">
                  <div class="col-md-9">
                    <span class="sub-heading">WE ARE OPTOMETRISTS!</span>
                    <ul>
                      <li routerLink="/who-we-are">Who Are We ?</li>
                      <li [routerLink]="['/meet-liliane']">
                        Meet Liliane Tanal OD., M.SC, Founder of VISIQUE
                        Optometrists
                      </li>
                      <li routerLink="newsletter">Newsletter</li>
                      <li routerLink="testimonial">Testimonials</li>
                      <li routerLink="events">Events</li>
                    </ul>
                  </div>
                  <div class="col-md-3 pr-0">
                    <img
                      class="sub-image"
                      src="../../assets/images/about-sub-image.png"
                      alt="about-sub-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="open-shop" (click)="openShopNavHide()">
            Shop
            <span>
              <img src="../../assets/images/dropdown-open.svg" alt="dropdown" />
            </span>
            <div class="top-megadropdown-wrapper">
              <div class="top-megadropdown-box">
                <div class="row">
                  <div class="col-md-4">
                    <span class="sub-heading">ONLINE SHOP</span>
                    <ul>
                      <li>
                        <a href="https://lensbooking.com/contact-lenses-online/" target="_blank"
                          >Medical Contact Lenses</a
                        >
                      </li>
                      <li>
                        <a href="https://lensbooking.com/colored-contact-lenses/" target="_blank"
                          >Colored Contact Lenses</a
                        >
                      </li>
                      <li >
                        <a routerLink="/collections" 
                          >Eyewear</a
                        >
                      </li>
                      <li>
                        <a href="https://lensbooking.com/contact-lens-solutions/" target="_blank"
                          >Contact Lens Solution</a
                        >
                      </li>
                      <li>
                        <a routerLink="/accessories"
                          >Accessories</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-5">
                    <span class="sub-heading" routerLink="/collections"
                      ><span class="sub-heading">COLLECTIONS</span></span
                    >
                    <div class="row">
                      <div class="col-md-4">
                        <ul>
                          <li routerLink="/collections">Burberry</li>
                          <li routerLink="/collections">Cartier</li>
                          <li routerLink="/collections">D&G</li>
                          <li routerLink="/collections">BVLGARI</li>
                          <li routerLink="/collections">Mykita</li>
                          <li routerLink="/collections">Oakley</li>
                          <li routerLink="/collections">Prada</li>
                        </ul>
                      </div>
                      <div class="col-md-4">
                        <ul>
                          <li routerLink="/collections">Gucci</li>
                          <li routerLink="/collections">LINDBERG</li>
                          <li routerLink="/collections">Lafont</li>
                          <li routerLink="/collections">Maui jim</li>
                          <li routerLink="/collections">Emporio Armani</li>
                          <li routerLink="/collections">Garret Leight</li>
                        </ul>
                      </div>
                      <div class="col-md-4">
                        <ul>
                          <li routerLink="/collections">Ray Ban</li>
                          <li routerLink="/collections">Thinoptics</li>
                          <li routerLink="/collections">Yves St laurent</li>
                          <li routerLink="/collections">Prada Linea Rossa</li>
                          <li routerLink="/collections">Life Italy</li>
                          <li routerLink="/collections">Rayban Junior Vista</li>
                          <li routerLink="/collections">...and many more</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pr-0">
                    <img
                      class="sub-image"
                      src="../../assets/images/shop-sub-image.png"
                      alt="about-sub-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- <li class="open-eyecare">
            Eyecare
            <span>
              <img src="../../assets/images/dropdown-open.svg" alt="dropdown" />
            </span>
            <div class="top-megadropdown-wrapper">
              <div class="top-megadropdown-box">
                <div class="row">
                  <div class="col-md-5">
                    <span class="sub-heading">BOOK AN APPOINTMENT</span>
                    <mat-card class="demo-inline-calendar-card">
                      <mat-calendar
                        [minDate]="date"
                        [(selected)]="selected"
                        (selectedChange)="onSelect($event)"
                      ></mat-calendar>
                    </mat-card>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6">
                        <ul>
                          <li
                            routerLink="/comprehensive-eye-refraction-exam"
                            (click)="openEyeCareNavHide()"
                          >
                            EYE EXAM
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul>
                          <li
                            routerLink="ophthalmic-lenses"
                            (click)="openEyeCareNavHide()"
                          >
                            OPHTHALMIC LENSES
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pr-0">
                    <img
                      class="sub-image"
                      src="../../assets/images/eyecare-sub-image.png"
                      alt="about-sub-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li> -->
          <!-- <li class="open-blog">
            <span routerLink="/blogs"> Blogs </span>
          </li> -->
        </ul>
      </div>
      <div class="header-center">
          <a routerLink="/" class="logo-wrapper"
            ><img
              src="../../assets/images/visique_logo_white_bg.png"
              class="img-fluid"
              alt="Logo"
          /></a>
      </div>
      <div class="header-right">
        <div class="d-none d-md-block text-right">
          <button class="book-button" routerLink="/book-an-appointment">
            Book An <span class="d-none d-lg-inline-block">Appointment</span>
          </button>
        </div>
        <button
          id="menu-btn"
          class="navbar-toggler brgn-btn collapsed d-md-none d-block"
          (click)="toggleClass()"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
      </div>
    </div>
    <div class="justify-content-end d-none d-md-flex">
      <p class="contact-us-content">
        LET’S TALK! Call us at 800 VISIQUE (8474 783)
      </p>
    </div>
  </div>
  <div class="lower-header d-none d-md-block">
    <div class="container">
      <div class="row justify-content-center">
        <ul class="bottom-navbar-c">
          <li>
            <span routerLink="/our-practice"> Our practice </span>
          </li>
          <li class="open-services" (click)="openServicesNavHide()">
            <span routerLink="/services"> Services </span>
            <div class="bottom-megadropdown-wrapper">
              <div class="bottom-megadropdown-box">
                <div class="bottom-dropdown-item">
                  <img routerLink="comprehensive-eye-refraction-exam"
                    src="../../assets/images/Comprehensive Eye Refraction exam.png"
                    alt="Comprehensive Eye Refraction exam"
                  />
                  <p routerLink="comprehensive-eye-refraction-exam">
                    Comprehensive Eye<br> Refraction exam
                  </p>
                </div>
                <div class="bottom-dropdown-item">
                  <img  routerLink="unmatched-portfolio-of-specialized-contact-lenses"
                    src="../../assets/images/Unmatched Portfolio of Specialized- Contact lenses.png"
                    alt="Unmatched Portfolio of Specialized-
                  Contact lenses"
                  />
                  <p
                    routerLink="unmatched-portfolio-of-specialized-contact-lenses"
                  >
                    Unmatched Portfolio of <br />
                    Specialized Contact lenses
                  </p>
                </div>
                <!-- <div class="bottom-dropdown-item">
                  <img
                    src="../../assets/images/In House Laboratory.png"
                    alt="In House Laboratory"
                  />
                  <p routerLink="in-house-laboratory">In House Laboratory</p>
                </div> -->
              </div>
            </div>
          </li>
          <li class="open-specialities" (click)="openSpecialitiesNavHide()">
            <span routerLink="specialities"> Specialities </span>
            <div class="bottom-megadropdown-wrapper">
              <div class="bottom-megadropdown-box">
                <div class="bottom-dropdown-item">
                  <img routerLink="semi-sclerals-and-sclerals-contact-lens-management"
                    src="../../assets/images/contact-lens-management.png"
                    alt="contact-lens-mangement"
                  />
                  <p
                    routerLink="semi-sclerals-and-sclerals-contact-lens-management"
                  >
                    Semi-Sclerals and Sclerals<br> contact
                    lens management
                  </p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/low-vision-aids"
                    src="../../assets/images/low-vision-aids.png"
                    alt="low-vision-aids"
                  />
                  <p routerLink="/low-vision-aids">Low vision Aids</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/presbyopia-management"
                    src="../../assets/images/Presbyopia Management.png"
                    alt="Presbyopia Managemen"
                  />
                  <p routerLink="/presbyopia-management">
                    Presbyopia Management
                  </p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/myopia-management"
                    src="../../assets/images/Myopia management.png"
                    alt="Myopia management"
                  />
                  <p routerLink="/myopia-management">Myopia Management</p>
                </div>
              </div>
            </div>
          </li>
          <li>
            <span routerLink="/collections"> Collection </span>
          </li>
          <li>
            <span routerLink="/contact-lenses"> Contact lenses </span>
          </li>
          <li class="open-kids-corner" (click)="openKidsCornerNavHide()">
            <span routerLink="/kids-corner"> Kids corner </span>
            <div class="bottom-megadropdown-wrapper">
              <div class="bottom-megadropdown-box">
                <div class="bottom-dropdown-item">
                  <img routerLink="/book-an-appointment"
                    src="../../assets/images/Kids Routine Check.png"
                    alt="Kids Routine Check"
                  />
                  <p routerLink="/book-an-appointment">Teens Routine Check</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/kids-eyewear"
                    src="../../assets/images/Kids Eyewear.png"
                    alt="Kids Eyewear"
                  />
                  <p routerLink="/kids-eyewear">Kids Eyewear</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/kids-sunglasses"
                    src="../../assets/images/Kids Sunglasses.png"
                    alt="Kids Sunglasses"
                  />
                  <p routerLink="/kids-sunglasses">Kids Sunglasses</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img routerLink="/kids-lenses"
                    src="../../assets/images/Kids Lenses.png"
                    alt="Kids Lenses"
                  />
                  <p routerLink="/kids-lenses">Kids Contact Lenses</p>
                </div>
              </div>
            </div>
          </li>
          <!-- <li class="navbar nav-item dropdown position-static">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Services 
            </a>
            <div
              class="dropdown-menu bottommegadropdown"
              aria-labelledby="navbarDropdown"
            >
        
                <div class="bottom-dropdown-menu">
                <div class="bottom-dropdown-item"> 
                  <img src="../../assets/images/Comprehensive Eye Refraction exam.png" alt="Comprehensive Eye Refraction exam">
                  <p>Comprehensive Eye Refraction exam</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img src="../../assets/images/Unmatched Portfolio of Specialized- Contact lenses.png" alt="Unmatched Portfolio of Specialized-
                  Contact lenses">
                  <p>Unmatched Portfolio of Specialized-<br>
                    Contact lenses</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img src="../../assets/images/In House Laboratory.png" alt="In House Laboratory">
                  <p>In House Laboratory</p>
                </div>
              </div>

            </div>
          </li>
          <li class="navbar nav-item dropdown position-static">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Specialities 
            </a>
            <div
              class="dropdown-menu bottommegadropdown"
              aria-labelledby="navbarDropdown"
            >
        
                <div class="bottom-dropdown-menu">
                <div class="bottom-dropdown-item"> 
                  <img src="../../assets/images/contact-lens-management.png" alt="contact-lens-mangement">
                  <p>Semi-Sclerals and Sclerals contact<br>
                    lens management</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img src="../../assets/images/low-vision-aids.png" alt="low-vision-aids">
                  <p>Low vision Aids</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img src="../../assets/images/Presbyopia Management.png" alt="Presbyopia Managemen">
                  <p>Presbyopia Management</p>
                </div>
                <div class="bottom-dropdown-item">
                  <img src="../../assets/images/Myopia management.png" alt="Myopia management">
                  <p>Myopia management</p>
                </div>
              </div>

            </div>
          </li> -->
          <!-- <li>Collection</li>
          <li>Contact lenses</li>
          <li>Kids corner</li> -->
        </ul>
      </div>
    </div>
  </div>
</header>

<div
  class="menu-side-wrapper d-md-none d-block"
  [class.expand-menu]="classApplied"
>
  <ul>
    <li routerLink="/" (click)="toggleClass()">HOME</li>
    <!-- <li routerLink="/who-we-are" (click)="toggleClass()">ABOUT</li> -->
    <li>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>ABOUT</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/who-we-are">Who Are We ?</li>
            <li (click)="toggleClass()" [routerLink]="['/meet-liliane']">
              Meet Liliane Tanal OD., M.SC, Founder of VISIQUE
              Optometrists
            </li>
            <li (click)="toggleClass()" routerLink="newsletter">Newsletter</li>
            <li (click)="toggleClass()" routerLink="testimonial">Testimonials</li>
            <li (click)="toggleClass()" routerLink="events">Events</li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>SHOP</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/collections">
              Collections
            </li>
            <li (click)="toggleClass()" routerLink="/events">EVENTS</li>
            <li (click)="toggleClass()" routerLink="/accessories">Accessories</li>
          </ul>
        </mat-expansion-panel>
        <!-- <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>EYE CARE</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/book-an-appointment">
              BOOK AN APPOINTMENT
            </li>
            <li (click)="toggleClass()" routerLink="/contact-lenses">
              CONTACT LENSES
            </li>
            <li (click)="toggleClass()" routerLink="/contact-us">CONTACT US</li>
          </ul>
        </mat-expansion-panel> -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Services</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/comprehensive-eye-refraction-exam">
              Comprehensive EyeRefraction exam
            </li>
            <li (click)="toggleClass()" routerLink="/unmatched-portfolio-of-specialized-contact-lenses">Unmatched Portfolio of
              Specialized Contact lenses</li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Specialities</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/semi-sclerals-and-sclerals-contact-lens-management">
              Semi-Sclerals and Sclerals contact
              lens management
            </li>
            <li (click)="toggleClass()" routerLink="/low-vision-aids">Low vision Aids</li>
            <li (click)="toggleClass()" routerLink="/presbyopia-management">Presbyopia Management</li>
            <li (click)="toggleClass()" routerLink="/myopia-management">Myopia Management</li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Kids corner</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li (click)="toggleClass()" routerLink="/book-an-appointment">
              Teens Routine Check
            </li>
            <li (click)="toggleClass()" routerLink="/kids-eyewear">Kids Eyewear</li>
            <li (click)="toggleClass()" routerLink="/kids-sunglasses">Kids Sunglasses</li>
            <li (click)="toggleClass()" routerLink="/kids-lenses">Kids Contact Lenses</li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </li>

    <li  (click)="toggleClass()" routerLink="/our-practice">Our Practice</li>
    <li  (click)="toggleClass()" routerLink="/contact-lenses">Contact Lenses</li>
  </ul>
  <div class="book-wrapper">
    <button routerLink="/book-an-appointment" (click)="toggleClass()">
      Book An Appointment
    </button>
  </div>
</div>
