<div class="container-fluid">
  <div class="newsletter-container">
    <h1 class="section-heading text-center">
      <span>Newsletter</span>
    </h1>
    <div class="card-container">
      <!-- <span class="issue-number">ISSUE | 2022</span>
      <span class="issue-date">21 Jan 2022</span>
      <p class="card-heading">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
        elementum id at mattis.
      </p> -->
      <img
        src="../../../assets/images/newsletter-page-1-banner.png"
        alt="newsletter-image"
      />
      <div class="card-description">
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
          rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
          elementum id at mattis. Tempor, fames scelerisque enim elit quis
          semper. Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam
          ac lectus. Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices
          in id felis tincidunt volutpat ultrices dictumst.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
          rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
          elementum id at mattis. Tempor, fames scelerisque enim elit quis
          semper. Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam
          ac lectus. Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices
          in id felis tincidunt volutpat ultrices dictumst.
        </p> -->
      </div>
    </div>
    <div class="card-container">
      <!-- <span class="issue-number">ISSUE # 121</span>
      <span class="issue-date">21 Jan 2022</span>
      <p class="card-heading">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
        rhoncus ?
      </p> -->
      <img
        src="../../../assets/images/newsletter-page-2-banner.png"
        alt="newsletter-image"
      />
      <div class="card-description">
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
          rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
          elementum id at mattis. Tempor, fames scelerisque enim elit quis
          semper. Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam
          ac lectus. Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices
          in id felis tincidunt volutpat ultrices dictumst.
        </p> -->
      </div>
    </div>
    <div class="card-container">
      <!-- <span class="issue-number">ISSUE # 121</span>
        <span class="issue-date">21 Jan 2022</span>
        <p class="card-heading">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
          rhoncus ?
        </p> -->
      <img
        src="../../../assets/images/newsletter-page-3-banner.png"
        alt="newsletter-image"
      />
      <div class="card-description">
        <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
            rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
            elementum id at mattis. Tempor, fames scelerisque enim elit quis
            semper. Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam
            ac lectus. Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices
            in id felis tincidunt volutpat ultrices dictumst.
          </p> -->
      </div>
    </div>
    <div class="card-container">
      <!-- <span class="issue-number">ISSUE # 121</span>
        <span class="issue-date">21 Jan 2022</span>
        <p class="card-heading">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
          rhoncus ?
        </p> -->
      <img
        src="../../../assets/images/newsletter-page-4-banner.png"
        alt="newsletter-image"
      />
      <div class="card-description">
        <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum
            rhoncus, platea at eu egestas. Morbi egestas sed morbi porta tincidunt
            elementum id at mattis. Tempor, fames scelerisque enim elit quis
            semper. Scelerisque pellentesque vitae aliquam nunc, nunc, nunc etiam
            ac lectus. Aliquet lacinia morbi dictumst mi. Quis adipiscing ultrices
            in id felis tincidunt volutpat ultrices dictumst.
          </p> -->
      </div>
    </div>
  </div>
</div>
