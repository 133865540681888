import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss'],
})
export class InstagramFeedComponent implements OnInit {
  accessToken = 'IGQVJXeFY5ZAHN1Ny1sODNjcGNfVldDZAnU5alNIYlpwR1U5VDM2QjlEaFE5b0ZAKY0FMbmpNdHJsOHBncWtwdlljbndlZAU1kS1N4aF80QjRrNS1OYkRrbFdDQ2RHcDk0dmZAnckFfb2otN3JjQVhqUUtvdQZDZD';
  NO_OF_IMAGES = 3;
  instaImages :any;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getInstaFeed();
  }

  getInstaFeed() {
    this.http.get(
      `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url&access_token=${this.accessToken}`
    ).subscribe((result:any) => {
      this.instaImages = result?.data;
      
    })
  }

  customOptions2: OwlOptions = {
    loop: true,
    center: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    autoplaySpeed: 500,
    navText: ['', ''],
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
}
