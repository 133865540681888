import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kids-sunglasses',
  templateUrl: './kids-sunglasses.component.html',
  styleUrls: ['./kids-sunglasses.component.scss']
})
export class KidsSunglassesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
