import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit {
  bookingForm: FormGroup;
  bookData: any;
  emailData:any;
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _api: ApiService,
    private _helper: HelperService
  ) {
    
      this.emailData = history?.state?.email;
      this.setForm();
  }

  ngOnInit(): void {

    this._helper.getbookData().subscribe((data: any) => {
      // this.userId = data?.id || this.userId;
      this.bookData = data;
      
      
    });
  }
  setForm(){
    let emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    let MOBILE_PATTERN = '^[0-9]{9,12}$';
    this.bookingForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern('^[A-Za-z ]*$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern('^[A-Za-z ]*$'),
        ],
      ],
      phoneNo: ['', [Validators.required, Validators.pattern(MOBILE_PATTERN)]],
      emailId: [this.emailData ? this.emailData : '', [Validators.required, Validators.pattern(emailRegex)]],
      comments: [''],
    });
  }
  onSave() {

    if (this.bookingForm.valid) {
      const formData = {
        data: {
          firstName: this.bookingForm.value.firstName,
          lastName: this.bookingForm.value.lastName,
          phoneNo: this.bookingForm.value.phoneNo,
          emailId: this.bookingForm.value.emailId,
          comments: this.bookingForm.value.comments,
          date: new Date(this.bookData.date).toDateString(),
          time: this.bookData.time,
        },
      };

      this._api
        .POST('api/booking-forms', formData)
        .subscribe((response: any) => {
          if(!response.data){
            const dialogRef = this.dialog.open(BookingFailurePopupComponent);
            dialogRef.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
            });
          }
          else{
            const dialogRef = this.dialog.open(BookingSuccessPopupComponent);
            dialogRef.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
            },(error) => {
              const dialogRef = this.dialog.open(BookingFailurePopupComponent);
            dialogRef.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
            });
            });
          }
          this.bookingForm.reset();
          
        });
    } else {
      this.bookingForm.markAllAsTouched();
    }
  }
}
@Component({
  selector: 'booking-success-popup',
  templateUrl: './booking-success-popup.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingSuccessPopupComponent implements OnInit {
  bookData: any;
  ngOnInit(): void {
    this._helper.getbookData().subscribe((data: any) => {
      this.bookData = data;
    });
  }

  constructor(
    private router: Router,
    private dialog: MatDialogRef<BookingSuccessPopupComponent>,
    private _helper: HelperService
  ) {}
  closeModal() {
    this.dialog.close({});
  }
  goToHome() {
    this.dialog.close({});
    this.router.navigateByUrl('/');
  }
}
@Component({
  selector: 'booking-failure-popup',
  templateUrl: './booking-failure-popup.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFailurePopupComponent implements OnInit {
  bookData: any;
  ngOnInit(): void {
    this._helper.getbookData().subscribe((data: any) => {
      // this.userId = data?.id || this.userId;
      this.bookData = data;
    });
  }

  constructor(
    private router: Router,
    private dialog: MatDialogRef<BookingFailurePopupComponent>,
    private _helper: HelperService
  ) {}
  closeModal() {
    this.dialog.close({});
  }
}
