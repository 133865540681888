<div class="container">
  <div class="card-container">
    <h1 class="section-heading text-center">
      <span>Meet Our Customers</span>
    </h1>

    <div class="card-wrapper">
      <div *ngFor="let item of content">
        <div
          class="singleCard"
          [ngStyle]="{
            'background-image': 'url(' + item.url + ')'
          }"
        >
          <div class="card-content">
            <h1 class="card-heading">
              <span> {{ item.description }}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
