<section class="pop-up-wrapper">
    <img class="close-icon" (click)="closeModal()" src="../../../assets/images/close-icon.svg" alt="close-icon">
    <img class="image" src="../../../assets/images/failure-image.svg" alt="success-icon">
    <h2>Unsucessfull Booking </h2>
    <div class="result">
        <span>
            <img src="../../../assets/images/calendar-brown.svg" alt="calender-icon">
            {{bookData.date | date : 'dd MMMM y' }}
        </span>
        <span>
            <img src="../../../assets/images/clock.svg" alt="clock-icon">
            {{bookData.time}}
        </span>
    </div>
    <div class="navigate-button">
        <button (click)="closeModal()">
            <span>Try Again</span>
        </button>
    </div>
</section>