<section class="homeslider-wrapper" *ngIf="sliderData">
  <div class="container-fluid p-0">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slider of sliderData; index as i; first as isFirst ">
      <ng-template carouselSlide >
        <div class="content-section" *ngIf = 'slider?.attributes?.bannerImage?.data?.attributes?.ext === ".jpg"' >
          <img
            class="w-100"
            [src]="[this.baseUrl + slider?.attributes?.bannerImage?.data?.attributes?.url]"
            alt="First slide"
          />
        </div>
        <!-- <div class="content-section" *ngIf = 'slider?.attributes?.bannerImage?.data?.attributes?.ext === ".mp4"' >
          <video class="w-100" autoplay="true" muted loop>
            <source [src]="[this.baseUrl + slider?.attributes?.bannerImage?.data?.attributes?.url]" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div> -->
        <div class="shop-now-section">
          <button (click)="goToBooking()">{{slider?.attributes?.shopNowButton}}</button>
        </div>
      </ng-template>
    </ng-container>
    </owl-carousel-o>
  </div>
</section>
