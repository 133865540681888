import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent, NavbarPopUp } from './navbar/navbar.component';
import { ViewModule } from './view/view.module';
import { NgxInstaModule } from 'ngx-insta';
import { FooterComponent } from './footer/footer.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthGuard } from './_services/auth-guard.service';
import { AuthService } from './_services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import {CustomHttpInterceptor } from './_services/http.interceptor';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SpinnerService} from './_services/spinner.service'
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";


@NgModule({
  declarations: [AppComponent, NavbarComponent, FooterComponent,NavbarPopUp],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ViewModule,
    NgxInstaModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    AccordionModule.forRoot(),
    MatIconModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    CarouselModule,
    NgxWhastappButtonModule
  ],
  providers: [AuthGuard, AuthService,
    SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },],
  bootstrap: [AppComponent],
})
export class AppModule {}
