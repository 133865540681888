import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private toastr : ToastrManager,private router : Router) { }
  private bookData = new BehaviorSubject<any>(null);

  setbookData(data: any) {
    this.bookData.next(data);
  }
  getbookData(): Observable<any>  {
    return this.bookData.asObservable();
  }

  showToast(type: number = 4, message: string = 'Bad Request') {
    switch (type) {
      case 1:
        this.toastr.successToastr(message);
        break;
      case 2:
        this.toastr.infoToastr(message);
        break;
      case 3:
        this.toastr.warningToastr(message);
        break;
      case 4:
        this.toastr.errorToastr(message);
        break;
    }
  }
}
