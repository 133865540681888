<div class="container-fluid">
  <div class="testimonials-container">
    <h1 class="section-heading text-center">
      <span>Events</span>
    </h1>
    <div class="card-container">
        <div class="row">
            <div class="col-md-6 text-center">
                <img src="../../../assets/images/event-image-new-1.jpg" alt="LAFONT Collection">
                <h5>COLORVISION Open House</h5>
                <p>May 28, 2022</p>
            </div>
            <div class="col-md-6  text-center">
                <img src="../../../assets/images/event-image-new-3.jpg" alt="Linberg Open House">
                <h5> Maui Jim Open House</h5>
                <p>June 4, 2022</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  text-center">
                <img src="../../../assets/images/event-image-new-2.png" alt="Launching Linberg Horn Collection">
                <h5>Mykita Open House</h5>
                <p> May 21, 2022</p>
            </div>
        </div>
    </div>

  </div>
</div>
