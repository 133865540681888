import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';

@Injectable()
export class AuthService {
  bookData: any;
  private isAuthenticated: boolean = false;
  constructor(private _helper: HelperService) {}

  public isRouteAuthenticated(): boolean {
    this._helper.getbookData().subscribe((data: any) => {
      this.bookData = data;
      if (this.bookData) {
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
      }
    });

    return this.isAuthenticated;
  }

  public setIsAuthenticated(isAuth: boolean): void {
    this.isAuthenticated = isAuth;
  }
}
