<div class="faq-wrapper">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Frequently Asked Questions</span>
    </h1>
    <div class="card-wrapper">
      <div class="row">
        <div class="col-md-5">
          <img
            class="w-100"
            src="../../../assets/images/faq-image.png"
            alt=""
          />
        </div>
        <div class="col-md-7 mt-3 mt-md-0">
          <div class="faq-container">
            <mat-accordion [togglePosition]="'before'" [@.disabled]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Lorem ipsum dolor sit amet consectet?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                  porro possimus quidem voluptatibus accusantium nobis minus
                  consequuntur soluta nesciunt, labore modi nulla iusto non
                  libero assumenda cum repudiandae at fugiat.
                </mat-panel-description>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Lorem ipsum dolor sit amet consectet?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                  porro possimus quidem voluptatibus accusantium nobis minus
                  consequuntur soluta nesciunt, labore modi nulla iusto non
                  libero assumenda cum repudiandae at fugiat.
                </mat-panel-description>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Lorem ipsum dolor sit amet consectet?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                  porro possimus quidem voluptatibus accusantium nobis minus
                  consequuntur soluta nesciunt, labore modi nulla iusto non
                  libero assumenda cum repudiandae at fugiat.
                </mat-panel-description>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Lorem ipsum dolor sit amet consectet?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                  porro possimus quidem voluptatibus accusantium nobis minus
                  consequuntur soluta nesciunt, labore modi nulla iusto non
                  libero assumenda cum repudiandae at fugiat.
                </mat-panel-description>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
