<div class="kids-corner-wrapper">
  <h2 class="section-heading text-center">
    <span>Kids Corner</span>
  </h2>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-md-3 mt-2 mb-2">
        <div class="kids-corner-item">
          <img
            src="../../assets/images/Kids Routine Check.png"
            alt="Kids Routine Check"
          />
          <p routerLink="/book-an-appointment">Teens Routine Check</p>
        </div>
      </div>
      <div class="col-md-3 mt-2 mb-2">
        <div class="kids-corner-item">
          <img src="../../assets/images/Kids Eyewear.png" alt="Kids Eyewear" />
          <p routerLink="/kids-eyewear">Kids Eyewear</p>
        </div>
      </div>
      <div class="col-md-3 mt-2 mb-2">
        <div class="kids-corner-item">
          <img
            src="../../assets/images/Kids Sunglasses.png"
            alt="Kids Sunglasses"
          />
          <p routerLink="/kids-sunglasses">Kids Sunglasses</p>
        </div>
      </div>
      <div class="col-md-3 mt-2 mb-2">
        <div class="kids-corner-item">
          <img src="../../assets/images/Kids Lenses.png" alt="Kids Lenses" />
          <p routerLink="/kids-lenses">Kids Contact Lenses</p>
        </div>
      </div>
    </div>
  </div>
</div>
