<div class="page-container">
    <div class="container-fluid">
        <h1 class="section-heading text-center">
            <span>Accessories </span>
        </h1>
        <div class="shape"><p>Swimming Goggles </p></div>
        <div class="card-container">
            <div class="card-wrapper  mb-3 col-md-12">
                <div class="card-description">
                    <h5>Leader Diving Mask</h5>
                    <p>
                        The leader diving mask can be customized based on your vision needs, so you do not have to worry
                        about missing underwater details. These come in blue and silver for adults, as well as blue and
                        yellow for children. They have a single shield design with side windows that allow you to get a
                        180° panoramic view. Risk of breakage is reduced with the tempered glass feature, and the body
                        is made of hypoallergenic silicon.
                    </p>
                </div>
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Swimming-img1.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
        <div class="card-container-1 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Swimming-img3.png" alt="newsletter-image" />
                </div>
                <div class="card-description">
                    <h5>Swimming Goggles</h5>
                    <p>
                        The beginner swimming goggles comes in two different
                        colors (Blue and black). The lenses are made of
                        polycarbonate with anti-fog coating so you do not have to
                        clean them. They ensure a 100% UV protection, and are
                        salt water resistant, as well as hypo allergenic
                    </p>
                </div>
            </div>
        </div>
        <div class="card-container-1 mt-5 ">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="card-description">
                    <h5>Swimming Goggles for Kids</h5>
                    <p>
                        The beginner swimming goggles for kids comes in two
                        different colors (Blue and pink). The lenses are made of
                        polycarbonate with anti-fog coating so you do not have to
                        clean them. They ensure a 100% UV protection, and are
                        salt water resistant, as well as hypo allergenic
                    </p>
                </div>
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Swimming-img2.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
        <div class="card-container-1 mt-5 ">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Swimming-img4.png" alt="newsletter-image" />
                </div>
                <div class="card-description">
                    <h5>Glazeable Swimming Goggles</h5>
                    <p>
                        The Glazeable swimming goggles lenses are made of
                        polycarbonate and are transparent. Individual Glazeable
                        for glazing with individual correction lenses, as well as
                        adjustable bridge (S, M, and L) and headband. They come
                        in two different colors, blue and black.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-two-container">
    <div class="container-fluid">
        <div class="shape"><p>Sports Goggles </p></div>
        <div class="card-container-2">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="card-description">
                    <h5>Leader Prox Sports Goggles</h5>
                    <p>
                        These leader prox sports goggles are suitable for mostly
                        all sports, including school or club sports, ensuring your
                        eyes are in optimum protection and comfort, and are found
                        in a variety of colors. The super tough nylon frame helps
                        with increasing impact protection, and impact resistant
                        polycarbonate lenses with 2mm thickness,
                        hard coating and UV protection. The frame is flexible and
                        its temple can be easily changed and adapted.
                    </p>
                </div>
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-img2.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
        <div class="card-container-2 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-img3.png" alt="newsletter-image" />
                </div>
                <div class="card-description">
                    <h5>Leader C2 Sports Goggles</h5>
                    <p>
                        These leader C2 sports goggles are suitable for nearly every sport, including school or club sports for optimum eye protection and comfort, and are found in a variety of colors. They are made of super tough nylon frame for impact protection, as well as high viscosity plano-polycarbonate lenses with 2mm center thickness, hard coating and UV protection.
                    </p>
                </div>
            </div>
        </div>
        <div class="card-container-3 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="card-description">
                    <h5>Leader Peloton Goggles</h5>
                    <p>
                        These leader peloton goggles are mostly used for running and biking, and come in a variety of frame colors and lens tint. They contain spring hinges for comfort, and adjustable silicone nose pads and temple tips. They also ensure 100% UV-A and UV-B protection up To 400nm.
                    </p>
                </div>  
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-img4.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
        <div class="card-container-3 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Sports-img4.png" alt="newsletter-image" />
                </div>
                <div class="card-description">
                    <h5>Overspecs With Mirror Coating</h5>
                    <p>
                        The colorful Overspecs with mirror coating ensures a 100% UV protection. This is a polarizing lens that has 80 to 85% grey tint with blue mirror. They come in three different colors: navy black, blue black, and red black that comes with red mirror instead of blue.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-two-container">
    <div class="container-fluid">
        <div class="shape"><p>Eye Patches:</p></div>
        <div class="card-container-4">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="card-description text-center">
                    <h5>Eye Patches Standard</h5>
                    <p>
                        The eye patches standard comes in three different versions based on preference: large concave, large flat, regular flat.
                    </p>
                </div>   
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-img5.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
        <div class="card-container-4 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-img6.png" alt="newsletter-image" />
                </div>

                <div class="card-description text-center">
                    <h5>Eye Patch Picolo</h5>
                    <p>
                        The eye patch picolo for kids is available in plastic or metal frame, based on preference. They also come in different colors and themes.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-two-container">
    <div class="container-fluid">
        <div class="shape"><p>Lens Cleaning:</p></div>
        <div class="card-container-4">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Lens-Cleaning-img1.png" alt="newsletter-image" />
                </div>

                <div class="card-description text-center">
                    <h5>Leader Lens Cleaner</h5>
                    <p>
                        The Leader Lens Cleaner is a pump lens cleaning spray used for all materials, including polycarbonate. They come in 5 different colors (yellow, orange, red, green, and blue).
                    </p>
                </div>
            </div>
        </div>
        <div class="card-container-4 mt-5">
            <div class="card-wrapper mb-3 col-md-12">
                <div class="card-description text-center">
                    <h5>Leader Anti-Fog Cloth</h5>
                    <p>
                        The Leader Lens Anti-Fog Cloth has a high effect on fog prevention for all materials especially polycarbonate and multi-coated lenses. These are easy to use and has a clean direct application.
                    </p>
                </div>
                <div class="col-md-6">
                    <img src="../../../assets/images/Accessories-Lens-Cleaning-img2.png" alt="newsletter-image" />
                </div>
            </div>
        </div>
    </div>
</div>