import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'shop-now-card',
  templateUrl: './shop-now-card.component.html',
  styleUrls: ['./shop-now-card.component.scss'],
})
export class ShopNowCardComponent implements OnInit {
  @Input() content: any;
  constructor(@Inject(PLATFORM_ID) private platform: Object) {}

  ngOnInit(): void {}

  goToBooking(link:any): void {
    if (isPlatformBrowser(this.platform)) {
    window.open(link, '_blank');
    }
  }
}
