import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-kids-lenses',
  templateUrl: './kids-lenses.component.html',
  styleUrls: ['./kids-lenses.component.scss']
})
export class KidsLensesComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platform: Object) { }

  ngOnInit(): void {
  }

  goToBooking(): void {
    if (isPlatformBrowser(this.platform)) {
    window.open('https://lensbooking.com/', '_blank');
    }
  }

}
