<div class="container-fluid">
  <div class="page-container">
    <h1 class="section-heading text-center">
      <span>In House Laboratory</span>
    </h1>
    <div class="card-container">
      <div class="row mb-3">
        <div class="col-md-6">
          <img
            src="../../assets/images/In House Laboratory-main.png"
            alt="Comprehensive Eye Refraction exam"
          />
          <h5 class="image-meta">In House Laboratory</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">Book An Appointment</button>
          </div>
        </div>
      </div>
      <div class="card-description">
        <h5>
          We cut your ophthalmic lenses at our laboratory for ultimate quality
          control.
        </h5>
        <p>
          We maintain a complete lens edging laboratory in our Naccache
          location, providing you a service to have your glasses ready in one
          hour*. Moreover, we provide prescription lens materials with spherical
          and aspheric designs with the latest coatings: scratch-resistant,
          anti-reflective optics and anti-fogging. We also offer fast repair and
          frame-cleaning service.
        </p>
      </div>
    </div>
  </div>
</div>
