<div class="insta-wrapper">
  <div class="container">
    <div class="card-container">
      <h1 class="section-heading">
        <span>Follow us on Instagram</span>
      </h1>
          <!-- <div class="inner-box" *ngFor="let image of instaImages">
                        <img src="{{image.media_url}}" alt="">
                        <video  width="300"  autoplay  controls>
                            <source  src="{{image.media_url}}" type="video/mp4" >
                            Your browser does not support the video tag.
                        </video>
                    </div> -->
                    <div class="div">
          <owl-carousel-o [options]="customOptions2" *ngIf="instaImages">
            <ng-template carouselSlide *ngFor="let image of instaImages">
              <div  class="gallery-box">
                <img *ngIf="image.media_type == 'IMAGE' || image.media_type == 'CAROUSEL_ALBUM'" src="{{ image.media_url }}"
                  class="img-wrapper"
                  alt="Gallery"
                />
                <video *ngIf="image.media_type == 'VIDEO'" width="350"  [muted]="'muted'" autoplay loop>
                  <source  src="{{image.media_url}}" type="video/mp4" >
                  Your browser does not support the video tag.
              </video>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
    </div>
  </div>
</div>
