<section class="footer-wrapper">
  <div class="container-fluid">
    <div class="footer-content">
      <div class="row md-pt-5">
        <div class="d-flex flex-column col-md-2 align-items-baseline">
          <ul>
            <li>
              <span>Information</span>
            </li>
            <li>
              <a routerLink="/who-we-are">About</a>
            </li>
            <li>
              <a routerLink="/contact-us/">Contact Us</a>
            </li>
            <!-- <li>
              <a routerLink="/faq">FAQ</a>
            </li> -->
            <li>
              <a routerLink="/terms-and-conditions">Terms & Conditions</a>
            </li>
            <li>
              <a routerLink="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 ps-md-5 ps-2">
          <ul>
            <li>
              <span>Shop</span>
            </li>
            <li>
              <a routerLink="/collections"
                >Optical and Sunglasses eyewear</a
              >
            </li>
            <li>
              <a href="https://lensbooking.com/" target="_blank"
                >Contact lenses</a
              >
            </li>
            <li>
              <a routerLink="/ophthalmic-lenses"
                >Ophthalmic lenses</a
              >
            </li>
            <li>
              <a routerLink="/low-vision-aids">Low Vision</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul>
            <li>
              <span>Address</span>
            </li>
            <li class="footer-text">
              Ground Floor, Unit GF378,<br />
              Dubai Hills Mall, Dubai Hills Estate,<br />
              Dubai
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul>
            <li>
              <span>Contact us</span>
            </li>
            <li>
              <a>Mobile: +971 800 VISIQUE</a>
            </li>
            <li>
              <a>customerservice@visiqueme.com</a>
            </li>
            <div class="subscription-heading">Subscribe to our newsletter</div>
            <div class="footer-form">
              <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribe()">
                <div class="form-group">
                  <input
                    type="text"
                    formControlName="email"
                    class="w-100"
                    name="cus-email"
                    placeholder="Your Email Address"
                  />
                  <div
                    *ngIf="
                      subscribeForm.controls['email'].invalid &&
                      (subscribeForm.controls['email'].dirty ||
                        subscribeForm.controls['email'].touched)
                    "
                  >
                    <div
                      *ngIf="subscribeForm.controls['email'].errors?.['required']"
                      class="error-txt"
                    >
                      Email is Required
                    </div>
                    <div
                      *ngIf="subscribeForm.controls['email'].errors?.['pattern']"
                      class="error-txt"
                    >
                      Enter valid Email
                    </div>
                  </div>
                </div>
                <button type="submit" class="send-button text-right">
                  Subscribe
                </button>
              </form>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="lower-footer-wrapper">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-4 footer-left">
        <button class="main-logo">
          <a routerLink="/" class="logo-wrapper"
            ><img
              src="/assets/images/footer-logo.svg"
              class="img-fluid"
              alt="Logo"
          /></a>
        </button>
      </div>
      <div class="col-md-4 footer-center">
        <div class="mb-2">
          <img src="/assets/images/visa.svg" alt="visa" />
          <img src="/assets/images/mastercard.svg" alt="mastercard" />
        </div>
        <div>
          <img src="/assets/images/footer-group2.svg" alt="aramex" />
        </div>
      </div>
      <div class="col-md-4 footer-right">
        <p>@2022* VISIQUE OPTOMETRISTS All Rights Reserved.</p>
      </div>
    </div>
  </div>
</section>
