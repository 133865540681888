import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-book-an-appointment',
  templateUrl: './book-an-appointment.component.html',
  styleUrls: ['./book-an-appointment.component.scss'],
})
export class BookAnAppointmentComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calender: MatCalendar<Date>;
  date = new Date();
  selected: any;
  selectedButtton: any = false;
  time: any;
  email: any = null;
  bookData: any;
  constructor(private router: Router, private _helper: HelperService) {
    this.email = history?.state?.email;
  }

  ngOnInit(): void {
    this._helper.getbookData().subscribe((data: any) => {
      this.bookData = data;
      this.selected = this.bookData?.date || this.date;
      this.calender._goToDateInView(this.selected, 'month');
    });
  }

  onSelect(value: any) {
    this.selected = value;
    // this.router.navigateByUrl('/newsletter');
  }

  handleTimeSelect(val: any) {
    this.time = val;
  }
  onBookNowClick() {
    this._helper.setbookData({ date: this.selected, time: this.time });
    console.log(this.email);

    this.router.navigate(['/booking-form'], { state: { email: this.email } });
  }
}
