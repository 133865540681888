<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Kids Eyewear</h1>
      <p>
        We offer extended warranties on children’s eyeglasses, allowing parents to feel
        more confident about their investment. Additionally, we have an extensive
        selection of choices for your little ones.
      </p>
      <p></p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading">
      <span>Kids Eyewear</span>
    </h1> -->
    <div class="card-container">
      <h1>Check-Out Kids Eyewear Online &amp; In-store Collection</h1>
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <img
            class="card-image"
            src="../../../assets/images/kids-eyewear.png"
            alt="logo"
          />
          <h5 class="image-meta">Kids Eyewear</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
       <p>VISIQUE Optometrists offers frames crafted from durable, lightweight materials
        such as stainless steel, acetate, and titanium. These robust materials are ideal for
        children who are tough on their glasses. Additionally, we provide extended
        warranties on children’s eyeglasses to ensure parents feel secure about their
        investment. We also have a wide variety of options available for your little ones.</p>
      </div>
      <h3>The Best Eyewear for Your Kids</h3>
      <p>
        When choosing eyewear for your children, there are several important factors to
        consider:
      </p>
      <p>
        Frame Material: Choose a frame material that ensures safety and comfort.
        For plastic frames, opt for BPA-free materials to avoid harmful chemicals.
        For metal frames, be aware of potential reactions, as some children may be
        sensitive to nickel or other metals.
      </p>
      <p>
        Lens Material: The material of the lenses is crucial for protection. Opt for
        lenses that offer defense against UV rays, with materials that block both
        UVA and UVB light. Polycarbonate lenses are a great choice as they are
        thin, impact-resistant, shatterproof, and provide 100% UV protection.
      </p>
      <p>
        Prescription Accuracy: Ensure that the correct prescription is used for your
        child’s glasses. An incorrect prescription can harm your child’s eyes and
        accelerate vision deterioration.
      </p>
      <p>
        Durability: Consider the durability of the eyeglasses, especially since
        children often break their glasses. Choose high-quality materials that can
        withstand everyday wear and tear to ensure long-lasting use.
      </p>
      <div class="logo-container">
        <h5 class="logo-heading text-center mb-3">Kids Eyewear Collection</h5>
        <div class="row">
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/lindberg-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/lafont-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/humphrey's-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/barbie-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/cars-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/hello-kitty-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/oio-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/hot-wheels-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/action-man-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/titeuf-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/fisher-price-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/ben10-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/ray-ban-logo.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/life-italia.png"
              alt="logo"
            />
          </div>
          <div class="col-md-3">
            <img
              class="m-2"
              src="../../../assets/images/oakley.png"
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <h2>FAQ’s Kids Eyewear and Eyecare</h2> -->
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  When should kids have their vision checked?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Your child should have their first comprehensive eye exam around 6 months of
                age. The next exam should be scheduled at age 3. After that, the frequency of eye
                exams depends on whether they need vision correction. If glasses are required,
                visits to the eye doctor should occur annually. If no corrective lenses are needed,
                schedule exams every two years.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are some signs or symptoms that I should be taking my child to the
                  doctor?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Some symptoms to watch for include delays in motor development, frequent eye
                rubbing, excessive blinking, or very little blinking.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Will I have problems getting my child to wear glasses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                The majority of children who need glasses will generally accept wearing them as
                they recognize the improvement in their vision and comfort. Toddlers are more
                likely to wear glasses when they are in a positive mood and may resist them when
                they are upset. For older children, you can talk to them about any vision
                challenges they face at school or consult their eye doctor to determine if they
                have minor refractive issues that might not need corrective lenses.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  I am worried that my 4-year-old child is not going to adjust to glasses, what
                  should I do to help?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Bring your child to a pediatric optician to select the most appropriate frame fit
                and material for them. Polycarbonate lenses are ideal for children as they are
                shatterproof, offering enhanced protection. Consider adding straps or silicone
                temple grips to keep the glasses secure and prevent them from slipping, making
                them well-suited for a 4-year-old. Children may take up to two weeks to adjust to
                their new lenses; however, if difficulties persist beyond this period, consult with
                their optician for further advice.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are there any glasses specifically used for sports or swimming?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Yes, there are glasses made for sports called recreational glasses. You can ask
                your optician for a prescription for sports glasses or even swimming goggles for
                your child!
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Do you offer eye exams for kids?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  VISIQUE provides eye examinations for children under the age of 14. You can
                
                  <span routerLink="/book-an-appointment"
                    >schedule an appointment</span
                  >
                  to see our skilled professionals.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are plastic or metal frames better for my children?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                You might think that plastic frames are durable, inexpensive,
                and can’t be broken easily. However, there are metal frames for
                children with the same qualities as plastic frames, but it is
                advisable to check whether your child has any metal allergies.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
