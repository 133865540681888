import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unmatched-portfolio-of-specialized-contact-lenses',
  templateUrl: './unmatched-portfolio-of-specialized-contact-lenses.component.html',
  styleUrls: ['./unmatched-portfolio-of-specialized-contact-lenses.component.scss']
})
export class UnmatchedPortfolioOfSpecializedContactLensesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
