import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../_services/helper.service';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  subscribeForm: FormGroup;
  emailLoading: boolean = false;
  formName: string = 'Subscribe Form';

  constructor(
    private _helper: HelperService,
    private formBuilder: FormBuilder,
    private _api: ApiService
  ) {}

  ngOnInit(): void {
    let emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }

  onSubscribe() {
    if (this.subscribeForm.valid) {
      this.emailLoading = true;
      let name = this.formName;
      let email = this.subscribeForm.value.email;
      this._api.WEBPOST(`api/subscribe`, { email }).subscribe((result) => {
        this._api
          .WEBPOST(`api/sendEmail`, { name, email })
          .subscribe((result) => {
            if (result.msg === 'success') {
              this._helper.showToast(1, 'Thank you for choosing us..');
              this.subscribeForm.reset();
            } else {
              this._helper.showToast(4, 'Something went wrong');
              this.subscribeForm.reset();
            }
          });
      });
    } else {
      this.subscribeForm.markAllAsTouched();
    }
  }
}
