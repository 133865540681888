import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-instgarm-card',
  templateUrl: './instgarm-card.component.html',
  styleUrls: ['./instgarm-card.component.scss']
})
export class InstgarmCardComponent implements OnInit {
  @Input() content:any
  constructor() { }

  ngOnInit(): void {
  }

}
