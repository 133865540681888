<div class="container">
  <div class="card-container">
    <h1 class="section-heading text-center">
      <span>Our Brands</span>
    </h1>

    <div class="card-wrapper">
      <div *ngFor="let item of content">
        <div
          class="singleCard"
          [ngStyle]="{
            'background-image': 'url(' + item.url + ')'
          }"
        >
          <div class="card-content">
            <h1 class="card-heading">{{ item.capitalHeading }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="more-button text-center">
      <button routerLink="/collections">View More</button>
    </div>
  </div>
</div>
