<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Kids Sunglasses</h1>
      <p>
        Adults are more likely to wear appropriate eyewear outside in sunny
        conditions. However, children are at high risk too because they often
        spend much more time outdoors. Therefore, children should wear
        sunglasses that provide proper UV protection and have impact-resistant
        lenses. They shouldn't be toy sunglasses, but real sunglasses that
        indicate the UV- protection level just as with adults.
      </p>
      <p></p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading">
      <span>Kids Sunglasses</span>
    </h1> -->
    <div class="card-container">
      <div class="row mb-3">
        <!-- <div class="col-md-6">
          <img
            class="card-image"
            src="../../../assets/images/kids-sunglasses.png"
            alt="logo"
          />
          <h5 class="image-meta">Kids Sunglasses</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div> -->
        <p>Children’s eyes are still in the developmental stage, so it is crucial that they are protected against UV 
          radiation using kids sunglasses. The over-exposure to UV radiation has proven to cause sunburn 
          for the eyes called photokeratitis. This can result in some temporary painful symptoms 
          like light sensitivity, red eyes, and excessive tearing. Later on, this can cause ocular diseases 
          like cataracts and macular degeneration and may cause partial or total vision loss.</p>
        <h1>Medical Benefits for Getting Sunglasses for Kids</h1>
        <p>
          It is important to note that children’s eyes are more prone to UV
          damage. This goes back to the fact that children’s eyes are larger
          than adults’, and the crystalline of the lens is clear, allowing more
          UV light to enter the eye and possibly harm their vision especially
          since they spend much more time playing outside. However, the negative
          effects that might occur due to UV light do not directly appear, and
          they start to emerge later in life. This can cause types of eye
          cancers and some eye complications, such as
        </p>
        <ul>
          <li>
            <strong>Pterygium:</strong> The growth of tissue in the white-colored
            part of the eye (conjunctiva). This could lead to visual impairment
            if not treated.
          </li>
          <li>
            <strong>Pinguecula:</strong> Yellow growths start to appear in the
            conjunctiva.
          </li>
          <li>
            <strong>Photokeratitis:</strong> ome Of its symptoms are redness,
            light sensitivity, tearing, and swelling. This Is due to the damage
            to the cornea’s outer surface.
          </li>
          <li>
            <strong>Cataract:</strong> Protein clumps in the lens form and grows
            to cloud the lens, which leads to vision impairment.
          </li>
        </ul>
        <p>
          Lastly but most importantly, teaching them some healthy habits
          regarding taking care of their eyes and vision is necessary at a young
          age. Wearing sunglasses is a task that they are going to carry with
          them into adulthood.
        </p>
        <div class="logo-wrapper">
          <div class="row">
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/lindberg-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/lafont-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/humphrey's-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/barbie-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/cars-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/hello-kitty-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/oio-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/hot-wheels-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/action-man-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/titeuf-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/fisher-price-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/ben10-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/ray-ban-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/life-italia.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/oakley.png"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is wearing sunglasses for kids only necessary when it is
                  sunny?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It is advised that kids wear sunglasses upon going outside even
                when it is not sunny since UV radiation can be reflected through
                the clouds. So, even when the weather is cloudy, make sure to
                always get a pair of sunglasses with you for your child.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Can toy sunglasses also protect the kids’ eyes against
                  radiation as well?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Random sunglasses do not protect against UV light, so you should
                specifically ask for those that ensure 100% protection against
                UV radiation for ultimate eye protection.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What do I do if my child already wears regular eyeglasses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                There are certain sunglasses for children that can be clipped
                on. Additionally, there are photochromic lenses that are clear
                indoors and turn dark in the sunlight. Upon wearing these
                sunglasses, kids are protected against UV light and have clear
                vision as well.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Does a darker pair of sunglasses mean more protection against
                  UV?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                The tint color or darkness degree is completely unrelated to the
                UV protection. However, if the darker glasses were not specific
                for UV protection, then it may be more of a risk for the child
                since it causes the pupil to dilate, causing more UV light to
                enter.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is there a specific style of sunglasses that offers my child’s
                  most protection?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Close-fitted styles block the most UV light rays. You can check
                our children’s sunglasses bulk on our website to see what suits
                your child’s personal style the most! You can also check out
                some children’s sunglasses with a strap to ensure they don’t
                fall off or lose them while playing.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
