<section class="pop-up-wrapper">
    <img class="close-icon" (click)="closeModal()" src="../../../assets/images/close-icon.svg" alt="close-icon">
    <img class="image" src="../../../assets/images/succes-image.svg" alt="success-icon">
    <h2>Successfully Booked</h2>
    <div class="result">
        <span>
            <img src="../../../assets/images/calendar-brown.svg" alt="calender-icon">
            {{bookData.date | date : 'dd MMMM y' }}
        </span>
        <span>
            <!-- <img src="../../../assets/images/clock.svg" alt="clock-icon"> -->
            {{bookData.time}}
        </span>
    </div>
    <div class="navigate-button">
        <button (click)="goToHome()">
            <img src="../../../assets/images/home.svg" alt="home-icon"><span>Home</span>
        </button>
    </div>
</section>