<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Kids Contact Lenses</h1>
      <p>
        According to pediatrics studies, wearing contact lenses improves how
        children and teenagers feel about their appearance and also improves
        their participation in activities more than wearing glasses.
      </p>
      <button (click)="goToBooking()">Shop Now</button>
    </div>
  </div>
  <!-- <div class="container-fluid"> -->
    <!-- <h1 class="section-heading">
      <span>Kids Contact Lenses</span>
    </h1> -->
    <!-- <div class="card-container">
      <div class="row mb-3">
        <div class="col-md-6">
          <img
            class="card-image"
            src="../../../assets/images/kids-contact-lenses-main.png"
            alt=""
          />
          <h5 class="image-meta mb-3 mt-3">Kids Contact Lenses</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button (click)="goToBooking()">Shop Now</button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
</div>
