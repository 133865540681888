import { Component, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { SpinnerService } from './_services/spinner.service';

import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'visique';
  whatsAppPhone: any = '971504891873';
  whatsAppTitle: any = 'hello there!'

  stringsToCheck = ['staging', 'newsletter', 'testimonial','events','meet-liliane','ophthalmic-lenses','comprehensive-eye-refraction-exam','in-house-laboratory','unmatched-portfolio-of-specialized-contact-lenses,','semi-sclerals-and-sclerals-contact-lens-management','myopia-management','presbyopia-management','services','kids-corner','specialities','low-vision-aids','kids-eyewear','kids-sunglasses','kids-lenses','our-practice','collections','blogs','who-we-are','contact-lenses','faq','book-an-appointment','contact-us','terms-and-conditions','privacy-policy','accessories','booking-form'];
  // constructor(@Inject(PLATFORM_ID) private platform: Object)  {
  // }

  showNavbarAndFooter: boolean = true;

  isLoading: Subject<boolean>;
  constructor(@Inject(PLATFORM_ID) private platform: Object, private spinnerService: SpinnerService, private router: Router) {
    this.isLoading = this.spinnerService.visibility;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showNavbarAndFooter = this.stringsToCheck.some(str => event.url.includes(str));
      }
    });
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platform)) {
      window.scroll(0, 0);
    }
  }




}
