<section class="contact-us-wrapper">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Appointment Request Form</span>
    </h1>
    <div class="card-wrapper">
      <div class="form-wrapper">
        <form [formGroup]="bookingForm" (ngSubmit)="onSave()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    bookingForm.controls['firstName'].invalid &&
                    (bookingForm.controls['firstName'].dirty ||
                      bookingForm.controls['firstName'].touched)
                  "
                >
                  <div
                    *ngIf="bookingForm.controls['firstName'].errors?.['required']"
                    class="error-txt"
                  >
                    First Name is Required
                  </div>
                  <div
                    *ngIf="bookingForm.controls['firstName'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid name
                  </div>
                  <div
                    *ngIf="bookingForm.controls['firstName'].errors?.['maxlength']"
                    class="error-txt"
                  >
                    Maximum 50 characters are allowed.
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    bookingForm.controls['lastName'].invalid &&
                    (bookingForm.controls['lastName'].dirty ||
                      bookingForm.controls['lastName'].touched)
                  "
                >
                  <div
                    *ngIf="bookingForm.controls['lastName'].errors?.['required']"
                    class="error-txt"
                  >
                    Last Name is Required
                  </div>
                  <div
                    *ngIf="bookingForm.controls['lastName'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid name
                  </div>
                  <div
                    *ngIf="bookingForm.controls['lastName'].errors?.['maxlength']"
                    class="error-txt"
                  >
                    Maximum 50 characters are allowed.
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Your Phone Number</mat-label>
                  <input maxlength="12" matInput formControlName="phoneNo" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    bookingForm.controls['phoneNo'].invalid &&
                    (bookingForm.controls['phoneNo'].dirty ||
                      bookingForm.controls['phoneNo'].touched)
                  "
                >
                  <div
                    *ngIf="bookingForm.controls['phoneNo'].errors?.['required']"
                    class="error-txt"
                  >
                    Phone is Required
                  </div>
                  <div
                    *ngIf="bookingForm.controls['phoneNo'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid Phone Number
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Your Email</mat-label>
                  <input matInput formControlName="emailId" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    bookingForm.controls['emailId'].invalid &&
                    (bookingForm.controls['emailId'].dirty ||
                      bookingForm.controls['emailId'].touched)
                  "
                >
                  <div
                    *ngIf="bookingForm.controls['emailId'].errors?.['required']"
                    class="error-txt"
                  >
                    Email is Required
                  </div>
                  <div
                    *ngIf="bookingForm.controls['emailId'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid Email
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Comments</mat-label>
                  <input matInput formControlName="comments" />
                </mat-form-field>
              </div>
            </div>
            <div class="submit-btn text-center mt-4">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
