<section class="pop-up-wrapper">
  <img
    class="close-icon"
    (click)="closeModal()"
    src="../.././assets/images/close-button-popup-new.svg"
    alt="close-icon"
  />
  <div class="top-pop-up-container">
    <div class="top-container">
      <h3 class="main-heading">LENSBY<span>MAIL</span> PROGARM</h3>

      <div class="main-description">
        <p class="para-one">
          “LENSBYMAIL” program makes contact lenses more convenient than ever.
        </p>
        <p class="para-two">
          Since we are committed to provide customer satisfaction, we have
          developed the “LENSBYMAIL” program that makes contact lenses more
          convenient than ever.
        </p>
      </div>
    </div>
    <div class="space"></div>
  </div>
  <div class="bottom-pop-up-container">
    <div class="bottom-container">
      <h5 class="bottom-description">
        With the LENSBYMAIL program, we will ship your contacts directly to your
        doorstep every month, with no shipping charges!
      </h5>
      <div class="input-container">
        <div class="content">
          <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribe()">
            <div class="row">
              <div class="col-md-6">
                <h5>Subscribe today and enjoy 2 months FREE contact lenses</h5>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <input
                    type="text"
                    formControlName="email"
                    placeholder="Your Email id"
                    class="form-conrol"
                  />
                  <div
                    *ngIf="
                      subscribeForm.controls['email'].invalid &&
                      (subscribeForm.controls['email'].dirty ||
                        subscribeForm.controls['email'].touched)
                    "
                  >
                    <div
                      *ngIf="subscribeForm.controls['email'].errors?.['required']"
                      class="error-txt"
                    >
                      Email is Required
                    </div>
                    <div
                      *ngIf="subscribeForm.controls['email'].errors?.['pattern']"
                      class="error-txt"
                    >
                      Enter valid Email
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <button type="submit">Subscribe</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
