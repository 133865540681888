<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Semi-Sclerals and Sclerals contact lens management</h1>
      <p>
        Keratoconus is a progressive eye condition where the normally spherical cornea
        becomes thinner and takes on a cone-like shape. This abnormal shape distorts
        light as it enters the eye, leading to blurred or distorted vision. Wearing scleral or
        semi-scleral lenses can make it easier for patients with corneal irregularities to
        comfortably wear contact lenses.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Semi-Sclerals and Sclerals contact lens management</span>
    </h1> -->
    <div class="card-container">
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <img
            src="../../assets/images/keratoconus-diagnosis-with-copy-space-degenerative-corneal-dystrophy 1.png"
            alt="keratoconus"
          />
          <h5 class="image-meta">keratoconus</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
        <h1>The Scleral Contact Lens Solution for Keratoconus</h1>
        <p>
          Keratoconus is a binocular eye disorder that mainly affects the cornea and is
          asymmetrical. It usually starts during puberty, around the teenage years or early
          twenties, and tends to progress until the mid-thirties. The condition causes the
          cornea to thin and become irregular in shape, affecting the clear, outer layer of
          the eye.
        </p>
        <h3>What Causes Keratoconus?</h3>
        <p>
          The exact cause of keratoconus remains unknown despite extensive research.
          Various theories suggest that it may be hereditary or linked to other conditions.
          Additionally, keratoconus has been associated with excessive eye rubbing,
          allergies, oxidative stress, and a history of poorly fitted contact lenses.
         </p>
        <h3>Keratoconus Treatment</h3>
        <p>
          In the initial stages of keratoconus, vision can often be corrected with eyeglasses
          or soft spherical and toric contact lenses. However, as the condition advances and
          the cornea becomes thinner and more irregular, these methods may no longer
          provide effective vision correction. At this stage, specialized contact lenses are
          necessary, including:
        </p>

        <ul>
          <li>Custom soft contact lenses</li>
          <li>Gas permeable contact lenses</li>
          <li>Piggyback contact lenses</li>
          <li>Hybrid contact lenses</li>
          <li>Scleral and semi-scleral lenses</li>
        </ul>
        <p>
          At VISIQUE Optometrists, we are experts in fitting specialty contact lenses and
          offer a broad selection to meet your needs.
        </p>
      </div>
      <img
        class="right-img"
        src="../../../assets/images/unmatched-portfolio-new-main-image.png"
        alt=""
      />
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I know if I have keratoconus?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  Keratoconus is diagnosed by an eye care professional during an eye exam. Key
                  tests include corneal topography to detect early signs, a slit-lamp exam to find
                  abnormalities, and pachymetry to measure the thinnest parts of the cornea.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is it possible for Keratoconus to be prevented?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>Currently, there is no known way to prevent keratoconus.</p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What is the difference between semi- scleral or scleral contact lenses and
                  regular contact lenses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  Scleral lenses offer similar comfort to soft lenses but have a larger diameter and
                  provide greater stability on the eye. Unlike conventional lenses, which cover only
                  part of the cornea, scleral lenses cover the entire corneal surface and rest on the
                  white part of the eye, known as the sclera.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are there types of scleral lenses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  There are three main types of scleral lenses, each varying in size and contact area:
                  
                </p>
                <ul>
                  <li>Semi-Scleral Lenses: Larger than conventional lenses, these rest between the cornea and the sclera.</li>
                  <li>Mini-Scleral Lenses: These cover the entire cornea and rest on the anterior sclera.</li>
                  <li>Full-Scleral Lenses: The largest of the three, providing more space between the lens and the cornea.</li>
                </ul>
                
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I know if I need scleral contact lenses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  You may begin wearing scleral lenses if you have irregular corneas causing vision
                  problems, eyes that are difficult to fit with conventional lenses, or dry eyes, as the
                  space between the lens and the eye helps keep the eye moist and comfortable.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are scleral lenses more expensive than regular fitting lenses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <p>
                  Because scleral lenses are custom-made to fit the specific prescriptions provided
                  by an eye specialist, they are typically more expensive than regular conventional
                  lenses.
                </p>
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
