<div class="service-wrapper">
  <h1 class="section-heading text-center">
    <span>Services</span>
  </h1>
  <div class="container-fluid">
    <div class="service-box">
      <div class="service-item">
        <img
          src="../../assets/images/Comprehensive-Eye-Refraction.png"
          alt="Comprehensive Eye Refraction exam"
        />
        <p routerLink="/comprehensive-eye-refraction-exam">
          Comprehensive Eye Refraction exam
        </p>
      </div>
      <div class="service-item ms-md-2">
        <img
          src="../../assets/images/Unmatched-Portfolio of-contact-lenses.png"
          alt="Unmatched Portfolio of Specialized-
                  Contact lenses"
        />
        <p routerLink="/unmatched-portfolio-of-specialized-contact-lenses">
          Unmatched Portfolio of <br/> Specialized
          Contact lenses
        </p>
      </div>
      <!-- <div class="service-item">
        <img
          src="../../assets/images/In-House-Laboratory.png"
          alt="In House Laboratory"
        />
        <p routerLink="/in-house-laboratory">In House Laboratory</p>
      </div> -->
    </div>
  </div>
</div>
