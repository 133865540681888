import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-semi-sclerals-and-sclerals-contact-lens-management',
  templateUrl: './semi-sclerals-and-sclerals-contact-lens-management.component.html',
  styleUrls: ['./semi-sclerals-and-sclerals-contact-lens-management.component.scss']
})
export class SemiScleralsAndScleralsContactLensManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
